import { Popover, Slider, Space } from "antd";
import React, { useEffect, useState } from "react";
import soundUpIcon from "assets/video-controllers/volume-up.svg";
import mutedIcon from "assets/video-controllers/mute.svg";
import { useTranslation } from "react-i18next";
const Volume = ({ audioRef, videoFullScreenWrapper }) => {
  const { t } = useTranslation();

  const [volumeValue, setVolumeValue] = useState(1);
  const [prevVolumeValue, setPrevVolumeValue] = useState(null);
  const audioElement = audioRef?.current?.getInternalPlayer();

  const handleVolumeChange = (value) => {
    setVolumeValue(value / 100);
    audioElement.volume = value / 100;
  };

  const handleMuted = () => {
    if (volumeValue) {
      setPrevVolumeValue(volumeValue);
      setVolumeValue(0);
      audioElement.volume = 0;
    }

    if (!volumeValue) {
      setVolumeValue(prevVolumeValue || 1);
      audioElement.volume = prevVolumeValue || 1;
      setPrevVolumeValue(0);
    }
  };

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.code === "KeyM") handleMuted();
    };
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [volumeValue]);

  const audioContent = (
    <div>
      <Slider
        vertical
        defaultValue={volumeValue * 100}
        onAfterChange={handleVolumeChange}
      />
    </div>
  );

  return (
    <div>
      <Popover
        content={audioContent}
        placement='topLeft'
        trigger='click'
        showArrow={false}
        overlayClassName='volume-element'
        getPopupContainer={() => videoFullScreenWrapper?.current}
      >
        <Space align='center' role='button'>
          <span className='mock-block'>
            <img
              src={!volumeValue ? mutedIcon : soundUpIcon}
              alt='volume'
              onClick={handleMuted}
            />
          </span>
          <span className='mock-block'>{t("volume")}</span>
        </Space>
      </Popover>
    </div>
  );
};

export default Volume;

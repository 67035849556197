import React from "react";
import { Button, Modal } from "antd";
import closeIcon from "../../../../assets/icons/close.svg";
import { t } from "i18next";
import ROUTES from "constants/_routes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SubscripeNowModal({ isModalVisible, onCancel }) {
  const { authorization } = useSelector((state) => state.auth);
  const isParentOnline = authorization?.online?.isParent;
  const navigate = useNavigate();
  return (
    <Modal
      centered
      open={isModalVisible}
      width={678}
      onCancel={onCancel}
      className='subscripe-now-modal modal-container text-center '
      footer={false}
      closeIcon={<img src={closeIcon} alt='icon' style={{ width: "24px" }} />}
    >
      <div className='text mt-3 mb-4'>
        {authorization?.token ? isParentOnline ? t("pleaseSubscribeFirstParent") : t("pleaseSubscribeFirstChild") : t("loginFirst")}
      </div>
      {isParentOnline || !authorization?.token ? (
        // {isParentOnline || !authorization?.token ? (
        <Button
          className='button-primary button-primary--signIn'
          onClick={() => {
            if (authorization?.token) {
              setTimeout(() => {
                document.body.style.overflow = "";
                onCancel();
                navigate(ROUTES.SUBSCRIPTION);
              }, 1);
            } else {
              // this arrange to fix the bug the overflow hidder still there after navigating to sing in and then the home
              setTimeout(() => {
                document.body.style.overflow = "";
                onCancel();
                navigate(ROUTES.SIGNIN);
              }, 1);
            }
          }}
        >
          {authorization?.token ?  t("subscribeNow"): t("signin")}
        </Button>
      ) : null}
    </Modal>
  );
}

export default SubscripeNowModal;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPrivacyPolicy } from "./network";

function PrivacyPolicy() {
  const [privacy, updatePrivacy] = useState("");
  const { i18n, t } = useTranslation();
  useEffect(() => {
    if (i18n.language) {
      getPrivacyPolicy(
        i18n.language,
        (success) => {
          updatePrivacy(success.data[0].privacyPolicy[i18n.language]);
        },
        (fail) => {},
      );
    }
  }, [i18n.language]);
  const privacyChunks = privacy.split("\n");
  return (
    <div>
      <div className='privacyPolicy mt-4 px-4'>
        <h5 className='title text-center my-2'>{t("privacyPolicy")}</h5>
        <div>
          {privacyChunks.map((sentence) => {
            return (
              <>
                <div>{sentence}</div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

import { useState, useEffect } from "react";
import { Image } from "react-image-and-background-image-fade";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { Row, Col, Button } from "antd";
import Skeleton from "react-loading-skeleton";
import { getBanner } from "./../network";
import watchNowIcon from "../../../assets/icons/watchNowIcon.svg";
import plusIcon from "../../../assets/icons/plusIcon.svg";
import playButton from "assets/icons/play-button.svg";
import plusButton from "assets/icons/plus-button.svg";
import { baseUrl } from "services";
import { toastError } from "helpers/toasters";
import { addDeleteWishList } from "modules/Shared/netwok";
import ROUTES from "constants/_routes";
import routes from "constants/_routes";
import checkedWishListButton from "assets/icons/checked-wish-list.svg";
import SubscripeNowModal from "modules/Subscription/models/SubscripeNowModal/SubscripeNowModal";
import DetectWindowSize from "helpers/DetectWindowSize";

function MainBanner() {
  const [breakPointData, setBreakPointData] = useState(null);
  const [banners, setBanners] = useState([]);
  const [selectedBannerIndex, setSelectedBannerIndex] = useState(0);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedBannerImg, setSelectedBannerImg] = useState(null);
  const { lang } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);
  const [isSubscribeNowModalVisible, updateIsSubscribeNowModalVisible] =
    useState(false);
  const [loading, setloading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPaid = authorization?.isPaid?.isPaid;
  const arrToLoad = new Array(3).fill(0);

  useEffect(() => {
    setloading(true);
    const payload = {
      lang,
      ...(authorization?.online?.id && {
        accountId: authorization.online.id,
      }),
    };
    getBanner(
      payload,
      (success) => {
        console.log("🚀 ~ useEffect ~ success:", success);

        setBanners(success.data);
        setSelectedBanner(success.data[0]);
        setSelectedBannerImg(encodeURI(baseUrl + success.data[0].imgPath));
        setloading(false);
      },
      (fail) => {
        if (fail.data.err_msg) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      },
    );
  }, [lang, authorization?.online?.id]);

  useEffect(() => {
    if (banners?.length) {
      const intervalId = setInterval(() => {
        setSelectedBannerIndex((selectedBannerIndex + 1) % banners?.length);
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [banners, selectedBannerIndex]);
  useEffect(() => {
    // Make sure selectedBannerIndex is a valid index
    if (
      banners?.length &&
      selectedBannerIndex >= 0 &&
      selectedBannerIndex < banners.length
    ) {
      const selectedData = banners[selectedBannerIndex];
      setSelectedBanner(selectedData);
      setSelectedBannerImg(encodeURI(baseUrl + selectedData.imgPath));
    }
  }, [selectedBannerIndex, banners]);

  // useEffect(() => {
  //   setSelectedBanner(banners[selectedBannerIndex]);
  //   setSelectedBannerImg(
  //     encodeURI(baseUrl + banners[selectedBannerIndex]?.imgPath)
  //   );
  // }, [selectedBannerIndex, banners]);
  useEffect(() => {
    if (
      banners?.length &&
      selectedBannerIndex >= 0 &&
      selectedBannerIndex < banners.length
    ) {
      const selectedData = banners[selectedBannerIndex];
      setSelectedBanner(selectedData);
      setSelectedBannerImg(encodeURI(baseUrl + selectedData.imgPath));
    }
  }, [selectedBannerIndex, banners]);
  function selectBanner(e, bannerId, bannerIndex) {
    e.stopPropagation();
    setSelectedBannerIndex(bannerIndex);
    const selectedData = banners.filter(
      (item) => item?.bannerId === bannerId,
    )[0];
    setSelectedBanner(selectedData);
    setSelectedBannerImg(encodeURI(baseUrl + selectedData.imgPath));
  }

  function openWatchNow(e, showFullScreen = false) {
    let data =
      selectedBanner.videoHeader._id +
      "-" +
      selectedBanner.video._id +
      "-" +
      (selectedBanner.isSeries ? 1 : 0) +
      "-" +
      showFullScreen;

    const watchNowDetails = {
      qp: data,
    };
    if (isPaid || selectedBanner?.video?.isFree) {
      navigate({
        pathname: ROUTES.WATCH_NOW,
        search: `?${createSearchParams(watchNowDetails)}`,
      });
    } else {
      updateIsSubscribeNowModalVisible(true);
      document.body.style.overflow = "hidden";
    }
  }

  function addToWishList(e) {
    e.stopPropagation();
    if (authorization?.online?.id) {
      const payload = {
        videoId: selectedBanner.videoHeader._id,
        isDeleted: selectedBanner.isWished,
        accountId: authorization?.online?.id,
      };
      addDeleteWishList(
        payload,
        (success) => {
          setSelectedBanner((prevState) => ({
            ...prevState,
            isWished: !prevState.isWished,
          }));

          setBanners((prevState) => [
            ...prevState.map((item) =>
              item.bannerId === selectedBanner.bannerId
                ? { ...item, isWished: !item.isWished }
                : item,
            ),
          ]);
        },
        (fail) => {
          if (fail.data.err_msg) {
            const errMsg = fail.data.err_msg;
            toastError(errMsg);
          }
        },
      );
    } else {
      document.body.style.overflow = "hidden";
      updateIsSubscribeNowModalVisible(true);
    }
  }

  const homeBannerStyle = {
    backgroundImage: `url(${selectedBannerImg?.replace(/%5C/g, "//")})`,
  };

  return (
    <>
      <DetectWindowSize getBreakPointData={(data) => setBreakPointData(data)} />
      <main
        className={`home-banner ${lang === "ar" ? "rtl" : ""}`}
        style={homeBannerStyle}
      >
        <Container>
          <div className='wrapper'>
            <div className='home-banner__data'>
              <div className='home-banner__season'>
                {selectedBanner?.videoHeader ? (
                  <span>{selectedBanner.videoHeader.title}</span>
                ) : (
                  <Skeleton width={100} />
                )}
              </div>
              <div className='home-banner__categories d-flex'>
                {loading ? (
                  <ul>
                    <li>
                      <Skeleton width={50} />
                    </li>
                    <li>
                      <Skeleton width={50} />
                    </li>
                  </ul>
                ) : (
                  <ul>
                    {selectedBanner?.category ? (
                      <li> {selectedBanner.category}</li>
                    ) : null}
                    {selectedBanner?.genre ? (
                      <li>{selectedBanner.genre}</li>
                    ) : null}
                  </ul>
                )}
              </div>
              <div className='home-banner__watch-now-add-list d-flex'>
                {selectedBanner ? (
                  // <div style={{ display: "flex", gap: "2rem" }}>
                  <div className='d-flex gap-3 gap-lg-4'>
                    <Button
                      className='button-primary button-primary--watchNow '
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                      onClick={(e) => {
                        openWatchNow(e, true);
                      }}
                    >
                      <img
                        src={watchNowIcon}
                        alt='watch now icon'
                        style={{
                          width: "16px",
                          height: "16px",
                          marginInlineEnd: ".5rem",
                        }}
                      />{" "}
                      {t("watchNow")}
                    </Button>

                    <button
                      className='button-secondary--addToWish '
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={(e) => {
                        addToWishList(e);
                      }}
                    >
                      <img
                        src={
                          selectedBanner.isWished
                            ? checkedWishListButton
                            : plusIcon
                        }
                        alt='add to wish list'
                        style={{
                          width: "16px",
                          height: "16px",
                          marginInlineEnd: ".5rem",
                        }}
                      />{" "}
                      {t("myList")}
                    </button>
                  </div>
                ) : (
                  <>
                    <Skeleton width={135} />
                    <Skeleton width={135} className='ms-2' />
                  </>
                )}
              </div>
              <div className='home-banner__desc'>
                {!loading ? (
                  selectedBanner?.description ? (
                    <p>{selectedBanner?.description}</p>
                  ) : null
                ) : (
                  <Skeleton count={1} width={280} />
                )}
              </div>
            </div>
            <div className='home-banner__navigators'>
              {breakPointData?.winWidth > 768 ? (
                <Row
                  xs={12}
                  md={24}
                  justify='center'
                  gutter={10}
                  className=''
                  style={{ minHeight: "150px" }}
                >
                  {selectedBanner ? (
                    banners.map((item, bannerIndex) => (
                      <Col
                        key={bannerIndex}
                        className='figure-holder d-flex align-items-center justify-content-center  p-0'
                        // style={{ width: "135px", height: "135px" }}
                      >
                        <figure
                          className={` ${
                            item.bannerId === selectedBanner.bannerId
                              ? "active-banner-wrapper"
                              : null
                          }`}
                        >
                          <Image
                            wrapperClassName={"image-wrapper"}
                            src={baseUrl + item.imgPath}
                            alt='navigator'
                            role='button'
                            className={`${
                              item.bannerId === selectedBanner.bannerId
                                ? "active-banner"
                                : null
                            }`}
                            onClick={(e) =>
                              selectBanner(e, item.bannerId, bannerIndex)
                            }
                          />
                        </figure>
                      </Col>
                    ))
                  ) : (
                    <>
                      {arrToLoad.map((item, _) => (
                        <Col key={_}>
                          <Skeleton circle width={96} height={96} />
                        </Col>
                      ))}
                    </>
                  )}
                </Row>
              ) : (
                <Row justify='center' gutter={20}>
                  {selectedBanner ? (
                    banners.map((item, bannerIndex) => (
                      <Col
                        key={bannerIndex}
                        className=' d-flex align-items-center justify-content-center p-0'
                      >
                        <div
                          className={`home-banner__navigator ${
                            breakPointData?.winWidth < 568 ? "mob" : "tab"
                          }`}
                          role='button'
                          onClick={(e) =>
                            selectBanner(e, item.bannerId, bannerIndex)
                          }
                        >
                          <span
                            className={`home-banner__nav-span ${
                              item?.bannerId === selectedBanner?.bannerId
                                ? "navigator-active"
                                : "navigator-color"
                            }`}
                          ></span>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <>
                      {arrToLoad.map((item, _) => (
                        <Col key={_}>
                          <Skeleton circle width={96} height={96} />
                        </Col>
                      ))}
                    </>
                  )}
                </Row>
              )}
            </div>
          </div>
        </Container>
        <SubscripeNowModal
          isModalVisible={isSubscribeNowModalVisible}
          onCancel={() => {
            document.body.style.overflow = "";
            updateIsSubscribeNowModalVisible(false);
          }}
        />
      </main>
    </>
  );
}

export default MainBanner;

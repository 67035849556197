import React from "react";
import PreviousVideoBtn from "./PreviousVideoBtn";
import SubtitleAndAudioSelect from "../SubtitleAndAudioSelect";
import { useTranslation } from "react-i18next";
import SettingList from "./SettingList";
import NextVideoBtn from "./NextVideoBtn";
import FullScreenBtn from "./FullScreenBtn";
import Progressbar from "../Progressbar";
import VideoProgressBar from "./VideoProgressbar";

const BottomController = ({
  videoRef,
  audioRef,
  isNotFirstVideoInSeries,
  isFullScreen,
  selectPrevVideo,
  isNotLastVideoInSeries,
  selectNextVideo,
  allSettingList,
  setAudio,
  setSubtitle,
  setQuality,
  currentVideoTime,
  currentVideoBufferd,
  videoDurationTime,
  handleTimeChange,
  audio,
  subtitle,
  quality,
  onFullScreenChange,
  videoFullScreenWrapper,
}) => {
  // const { t, i18n } = useTranslation();

  return (
    <div className='video-controller__lower' dir='ltr'>
      <div className='d-flex mb-4'>
        <div className='controllers-settings'>
          {navigator.onLine && (
            <PreviousVideoBtn
              isNotFirstVideoInSeries={isNotFirstVideoInSeries}
              selectPrevVideo={selectPrevVideo}
            />
          )}
          <SettingList
            videoRef={videoRef}
            audioRef={audioRef}
            allSettingList={allSettingList}
            setAudio={setAudio}
            setSubtitle={setSubtitle}
            setQuality={setQuality}
            audio={audio}
            subtitle={subtitle}
            quality={quality}
            videoFullScreenWrapper={videoFullScreenWrapper}
          />
          {navigator.onLine && (
            <NextVideoBtn
              isNotLastVideoInSeries={isNotLastVideoInSeries}
              selectNextVideo={selectNextVideo}
            />
          )}
          <FullScreenBtn
            isFullScreen={isFullScreen}
            onFullScreenChange={onFullScreenChange}
          />
          {/* Progress bar */}

          {/* <div className=" controllers-settings__item">
            <SubtitleAndAudioSelect
              items={videoData?.audios}
              title={t("audio")}
              isAudio={true}
              showList={showLists.audios}
              setShow={() => {
                handleShowHideLists("audios", false);
              }}
              onTrackChange={onTrackChange}
              selectedAudio={selectedAudio}
            />
            <div
              className="controllers-settings__button"
              onClick={() => {
                handleShowHideLists("audios", !showLists.audios);
              }}
            >
              <span
                className={`${
                  i18n.language === "ar" ? "flex-row-reverse " : "flex-row"
                } d-flex align-items-center cursor-pointer`}
              >
                <img src={audioIcon} alt="select audio" className="mx-2" />
                <p className="user-select-none">{t("audio")}</p>
              </span>
            </div>
          </div> */}
          {/* Subtitle */}
          {/* <div className="controllers-settings__item">
            <SubtitleAndAudioSelect
              isSubtitle={true}
              title={t("subtitle")}
              items={subtitlesList}
              showList={showLists.subtitles}
              setShow={() => handleShowHideLists("subtitles", false)}
              onTrackChange={onTrackChange}
              selectedSubtitle={selectedSubtitle}
            />
            <div
              className="controllers-settings__button"
              role={"button"}
              onClick={() =>
                handleShowHideLists("subtitles", !showLists.subtitles)
              }
            >
              <span
                className={`${
                  i18n.language === "ar" ? "flex-row-reverse " : "flex-row"
                } d-flex align-items-center`}
              >
                <img src={ccIcon} alt="select subtitle" className="mx-2" />
                <p className="user-select-none">{t("subtitles")}</p>
              </span>
            </div>
          </div> */}
          {/* Quality */}
          {/* <div className="controllers-settings__item cursor-pointer">
            <SubtitleAndAudioSelect
              isQuality={true}
              title={t("quality")}
              items={qualityList}
              showList={showLists.quality}
              setShow={() => handleShowHideLists("quality", false)}
              onTrackChange={onTrackChange}
              selectedQuality={selectedQuality}
            />
            <span
              className={`${
                i18n.language === "ar" ? "flex-row-reverse " : "flex-row"
              } d-flex align-items-center`}
              onClick={() => handleShowHideLists("quality", !showLists.quality)}
            >
              <img
                role={"button"}
                src={settingsIcon}
                alt="settings"
                className="mx-2"
              />
              <p className="user-select-none">{t("quality")}</p>
            </span>
          </div> */}
          {/* controllers */}
          {/* <div
            className="video-controller__sound controllers-settings__item"
            onClick={() => {
              handleShowHideLists("volume", true);
            }}
            // onClick={() => {
            //   handleShowHideLists("volume", false);
            // }}
          >
            <div
              className={`video-controller__sound__wrapper ${
                showLists.volume ? "visible" : "invisible"
              } ${i18n.language === "ar" && "right"}`}
              onMouseLeave={() => {
                handleShowHideLists("volume", false);
              }}
            >
              <Slider
                className="sound-slider"
                vertical
                min={0}
                max={100}
                value={soundValue}
                onChange={handleChangeSound}
                trackStyle={{
                  backgroundColor: isMuted ? "#EAEAEA" : "#ED2024",
                  cursor: "pointer",
                }}
                railStyle={{
                  background: "#313131",
                  cursor: "pointer",
                }}
                handleStyle={{
                  background: "white",
                  borderColor: isMuted ? "#EAEAEA" : "#ED2024",
                  boxShadow: "none",
                  opacity: "1",
                }}
                style={{
                  borderRadius: 0,
                }}
              />
            </div>

            <div className="controllers-settings__button" role="button">
              <div
                className={`${
                  i18n.language === "ar" ? "flex-row-reverse " : "flex-row"
                } d-flex align-items-center cursor-pointer`}
              >
                <img
                  onClick={() => {
                    handleChangeSound("mute");
                  }}
                  src={isMuted ? mutedIcon : soundUpIcon}
                  alt="sound-level"
                  style={{
                    width: 25,
                  }}
                />
                <p className="user-select-none mx-2">{t("volume")}</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <VideoProgressBar
        videoRef={videoRef}
        currentVideoTime={currentVideoTime}
        currentVideoBufferd={currentVideoBufferd}
        videoDurationTime={videoDurationTime}
        handleTimeChange={handleTimeChange}
      />
    </div>
  );
};

export default BottomController;

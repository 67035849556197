import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import { I18nextProvider } from "react-i18next";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import store from "./store";
import i18next from "localization/i18next";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.variable.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "assets/styles/styles.scss";
import "index.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import swDev from "./swDev";

const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store);
ConfigProvider.config({
  theme: {
    primaryColor: "#ed2024",
  },
});
const Index = () => {
  const [direction, setDirection] = useState("ltr");
  const getCurrentDir = (dir) => {
    setDirection(dir);
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <ConfigProvider direction={direction}>
            <BrowserRouter>
              <App getCurrentDir={getCurrentDir} />
            </BrowserRouter>
          </ConfigProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

root.render(<Index />);

// serviceWorkerRegistration.register();
swDev();

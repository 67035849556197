import WebsiteTitle from "helpers/WebsiteTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Row, Col, Button, Modal } from "antd";
import Skeleton from "react-loading-skeleton";
import VideoListItem from "modules/Shared/VideoListItem";
import {
  addDataToIndexDB,
  deleteSpecificDataFromDB,
  getAllDataFromDB,
} from "helpers/indexdb";
import { dbInfo } from "../../helpers/indexdb";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import noVideos from "../../assets/icons/noVideosInDownloads.json";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/_routes";
import { getVideoToDownloads } from "modules/WatchNow/network";
import closeIcon from "assets/icons/close.svg";
import useSyncDeleteDownloads from "./useSyncDeleteDownloads";

function MyDownloads() {
  const [myDownloads, setMyDownloads] = useState([]);
  const { authorization } = useSelector((state) => state.auth);
  const [myDownloadsLoading, setMyDownloadsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDownloadingModal, setshowDownloadingModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useSyncDeleteDownloads(authorization);

  const closeDownloadModalHanlder = () => {
    setshowDownloadingModal(false);
  };
  const showDownloadModalHanlder = () => {
    setshowDownloadingModal(true);
  };

  const updateDownloadedList = () => {
    getVideoToDownloads(
      { userAccountId: authorization?.online?._id },
      (success) => {
        getAllDataFromDB(
          authorization?.online?._id,
          dbInfo.dbVersion,
          (data) => {
            const downloads = success.data.map((obj1) => {
              if (data.some((obj2) => obj2.videoId === obj1.videoId)) {
                return {
                  ...obj1,
                  isDownloaded: true,
                };
              }
              return obj1;
            });

            setMyDownloadsLoading(false);
            setMyDownloads(downloads);
          },
          authorization?.online?._id,
        );
      },
    );
  };

  const downloadVideoHandler = (videoData, e) => {
    e.stopPropagation();
    showDownloadModalHanlder();
    addDataToIndexDB(
      authorization?.online?._id,
      dbInfo.dbVersion,
      videoData,
      () => {
        updateDownloadedList();
        closeDownloadModalHanlder();
      },
      authorization?.online?._id,
    );
  };

  useEffect(() => {
    if (navigator.onLine) {
      getVideoToDownloads(
        { userAccountId: authorization?.online?._id },
        (success) => {
          getAllDataFromDB(
            authorization?.online?._id,
            dbInfo.dbVersion,
            (data) => {
              if (data.length > success.data.length) {
                const obj = data.filter(
                  (obj1) =>
                    !success.data.some((obj2) => obj1.videoId === obj2.videoId),
                );

                obj.forEach((video) => {
                  deleteSpecificDataFromDB(
                    authorization.online._id,
                    dbInfo.dbVersion,
                    authorization.online._id,
                    video.videoId,
                    () => {},
                  );
                });
              }
              const downloads = success.data.map((obj1) => {
                if (data.some((obj2) => obj2.videoId === obj1.videoId)) {
                  return {
                    ...obj1,
                    isDownloaded: true,
                  };
                }
                return obj1;
              });

              setMyDownloads(downloads);
              setMyDownloadsLoading(false);
            },
            authorization.online._id,
          );
        },
      );
    } else {
      getAllDataFromDB(
        authorization?.online?._id,
        dbInfo.dbVersion,
        (data) => {
          setMyDownloads(data);
          setMyDownloadsLoading(false);
        },
        authorization?.online?._id,
      );
    }
  }, [authorization?.online?._id]);

  useEffect(() => {
    if (navigator.onLine) updateDownloadedList();
  }, [authorization?.online?._id]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noVideos,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleBackToHome = () => {
    navigate(ROUTES.HOME);
  };
  return (
    <div className=' myDownloads'>
      <WebsiteTitle title={t("myDownloads")} />
      <section className='main-section myDownloads'>
        <Container className='d-flex flex-1 flex-column'>
          <div className='myDownloads__title'>
            <span>{t("myDownloads")}</span>
          </div>
          <Row
            className='flex-1'
            gutter={[40, 40]}
            style={{ marginBottom: "1.8rem" }}
          >
            {myDownloadsLoading ? (
              new Array(4).fill(1).map((item, _) => (
                <Col xs={24} md={6} key={_}>
                  <Skeleton width={"100%"} height={232} borderRadius={25} />
                </Col>
              ))
            ) : !myDownloads.length ? (
              <div className='text-center m-auto'>
                <Lottie
                  isClickToPauseDisabled={true}
                  options={defaultOptions}
                  height={180}
                  width={180}
                />
                <div className='fw-600 f-25 noDownloads-title'>
                  {t("noVideosInDownload")}
                </div>
                <div className='noDownloads-Info mb-4'>
                  {t("goHomeToAddInDownloads")}
                </div>
                <Button className='button-primary' onClick={handleBackToHome}>
                  {t("backToHome")}
                </Button>
              </div>
            ) : (
              <div className='myDownloads-container'>
                {myDownloads?.map((item, _) => {
                  const details = {
                    header: {
                      imgPath: item.imgPath,
                      title: item.title,
                      _id: item.videoHeaderId,
                      isSeries: item.isSeries,
                      videoId: item.videoId,
                      genre: item?.genre,
                      category: item?.category,
                      isDownloaded: item.isDownloaded,
                      ...(item.subtitle ? { subtitleId: item.subtitle } : {}),
                    },
                  };
                  console.log("🚀 ~ {myDownloads?.map ~ details:", details);
                  return (
                    <VideoListItem
                      details={details}
                      isOffline={true}
                      downloadedVideoData={item}
                      updateDownloadedList={updateDownloadedList}
                      key={_}
                    />
                  );
                })}
              </div>
            )}
          </Row>
        </Container>
      </section>

      <Modal
        centered
        open={showDownloadingModal}
        width={800}
        onCancel={closeDownloadModalHanlder}
        className='download-modal modal-container'
        footer={false}
        closeIcon={<img src={closeIcon} alt='icon' style={{ width: "30px" }} />}
      >
        {isDownloading && (
          <div className='text-center'>
            <h5 className='text-center fw-700 f-25 title my-4'>
              {t("downloading")}
            </h5>
            <div className='my-4 py-4'>
              <Lottie
                isClickToPauseDisabled={true}
                options={defaultOptions}
                height={120}
                width={120}
              />
            </div>
          </div>
        )}
      </Modal>
      {/* <DownloadModal
        isModalVisible={isDownloadModalVisible}
        onCancel={() => {
          setIsDownloadModalVisible(false);
          // updateDownloadedState();
          // getVideoDetailss();
        }}
        // videoDetails={videoDetails}
      /> */}
    </div>
  );
}

export default MyDownloads;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Form, Select, Button, Input, InputNumber } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  createUserAccount,
  getAllLanguagesAdmin,
  logoutUserAccount,
} from "../Authentications/network";
import editPencil from "assets/images/editPencil.svg";
import editIcon from "assets/icons/pencil.png";

import pencilIcon from "assets/icons/pencilIcon.svg";
import {
  addUserAccount,
  changeAuthData,
  editUserAccountR,
  logout,
  onlineUser,
  resetOnlineUser,
} from "store/authorization";
import ROUTES from "constants/_routes";
import { getAllLanguages } from "../Authentications/network";
import { baseUrl } from "services";
import { editUserAccount } from "../Authentications/network";
import { addChildData, resetChildData } from "store/addChild";

import Spinner from "modules/Shared/Spinner";
import InputField from "modules/Authentications/components/InputField";
import { addTokenToMedia } from "helpers/addTokenToMedia";
import { getUserAccounts } from "modules/WatchNow/network";

const AddChilds = () => {
  const [allLanguages, setAllLanguages] = useState([]);
  // const [agee, setAgee] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [avatarId, setAvatarId] = useState("");
  const [avatarPath, setAvatarPath] = useState("");
  const [avatarError, setAvatarError] = useState("");
  const [loading, setLoading] = useState(false);
  const { authorization } = useSelector((state) => state.auth);
  const { lang } = useSelector((state) => state.lang);
  const { childData } = useSelector((state) => state?.childData);
  const [isLoaded, updateIsLoaded] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Check If Parent Is Online OR Not To Show Edite Field
  const { isParent, mode, from } = location.state;

  //get parentId
  let parentId = authorization?.userAccounts?.filter(
    (parent) => parent?.isParent === true,
  )[0]?.id;

  //get selected kid Id from url
  const kidId = params?.accountId || childData?.kidId;

  // To Logout Parent Account After Add Child
  const { deviceId, _id: userId, online } = authorization;

  const [form] = Form.useForm();
  const allValues = {
    name: Form.useWatch("name", form),
    age: Form.useWatch("age", form),
    language: Form.useWatch("language", form),
    avatarId: avatarId,
    kidId: params?.accountId,
  };

  //get All Languages
  useEffect(() => {
    getAllLanguages(
      { lang },
      (success) => {
        setAllLanguages(success.data);
        updateIsLoaded(true);
      },
      (fail) => {
        setAllLanguages("No Data");
      },
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [lang]);

  useEffect(() => {
    if (childData) {
      const { name, age, language, avatarId } = childData;
      // setAgee(age);
      form.setFieldsValue({
        name,
        age,
        language,
        avatarId,
      });
      setAvatarId(avatarId);
    } //eslint-disable-next-line
  }, [childData]);

  useEffect(() => {
    if (location?.state?.avatarPath) {
      setAvatarId(location?.state?.avatarId);
      setAvatarPath(location?.state?.avatarPath);
    }
    if (kidId) {
      // setMode("edit");
      if (location?.state?.user) {
        const { age, name, avatarId, avatar, preferedLanguage } =
          location.state.user;
        form.setFieldsValue({
          name,
          age,
          language: preferedLanguage,
          avatarId,
          avatarPath: baseUrl + avatar,
        });
        setAvatarId(avatarId);
      }
    } else {
      // setMode("add");
    } //eslint-disable-next-line
  }, [kidId, authorization, location]);

  function handleOnKeyDown(event) {
    if (event.key === "e") {
      event.preventDefault();
    }
  }
  const preventMinus = (e) => {
    if (e.code === "Minus" || e.code.includes("Key")) {
      e.preventDefault();
    }
  };
  // function handleAgeOnChange(event) {
  //   if (agee > 0 && agee < 99) {
  //     setAgee(event.target.value);
  //   }
  // }
  const getAllUsers = () => {
    const payload = {
      userId: authorization._id,
      lang,
    };

    getUserAccounts(
      payload,
      (success) => {
        dispatch(changeAuthData(success.data));
      },
      (fail) => { },
    );
  };

  function onSubmit({ name, age, language }) {
    if (mode === "edit") {
      setLoading(true);
      const data = {
        avatarId,
        nameEn: name,
        nameAr: name,
        age,
        preferedLanguage: language,
        kidId,
        parentId,
      };
      if (isParent && !parentId) {
        data.parentId = params.accountId;
      }
      editUserAccount(
        data,
        (success) => {
          setLoading(false);
          if (success.isSuccess) {
            const avatarPathparts = avatarPath.split("/");
            const avatarEndPath = avatarPathparts[avatarPathparts.length - 1];
            const updatedData = {
              avatarId: success.data?.avatarId,
              avatar: avatarEndPath,
              name,
              isParent: success.data.isParent,
              id: success.data?._id,
              age: success.data?.age,
              preferedLanguage: success.data.preferedLanguage,
            };
            dispatch(
              changeAuthData({
                name,
              }),
            );
            if (authorization?.online?.isParent && isParent) {
              dispatch(
                onlineUser({
                  ...authorization?.online,
                  ...location?.state?.avatarData,
                }),
              );
            }

            toastSuccess(t("userDataUpdatedSuccessfully"));
            dispatch(editUserAccountR(updatedData));
            dispatch(resetChildData());
            navigate(ROUTES.MANAGE_ACCOUNT);
            getAllUsers();
          }
        },
        (fail) => {
          setLoading(false);
          if (fail.data) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        },
      );
    }

    if (mode === "add") {
      if (!location.state?.avatarId) {
        setAvatarError(t("selectAvatar"));
      } else {
        setAvatarError("");
      }
      if (name?.length > 0) {
        setLoading(true);
        const payload = {
          deviceId,
          userId,
          accountId: parentId,
        };

        const data = {
          parentId,
          avatarId,
          nameAr: name,
          nameEn: name,
          age,
          preferedLanguage: language,
        };

        createUserAccount(
          data,
          (success) => {
            setLoading(false);
            if (success.isSuccess) {
              toastSuccess(t("accountCreatedSuccessfully"));
              dispatch(addUserAccount(data));
              if (from === "/watchers") {
                dispatch(resetChildData());
                dispatch(resetOnlineUser());
                dispatch(changeAuthData({ isEnteredUserAccount: null }));
                logoutUserAccount(
                  payload,
                  (success) => { },
                  (fail) => { },
                );
                setTimeout(() => {
                  navigate(ROUTES.WATCHERS);
                }, 0);
              }
              if (from === "/manage-account") {
                navigate(ROUTES.MANAGE_ACCOUNT);
              }
            }
          },
          (fail) => {
            setLoading(false);
            if (fail?.data) {
              toastError(fail.data.msg);
            } else {
              toastError();
            }
          },
        );
      } else {
      }
    }
  }
  const preventNumbers = (e) => {
    const allowedChar = ["ط", "د", "ظ", "ذ", "ج", "ك", "ز", "و", " "];
    if (!e.code.includes("Key") && !allowedChar.includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <section
      style={{ marginBottom: "4rem" }}
      className='main-section add-childs d-flex flex-column flex-1'
    >
      {isLoaded ? (
        <Container>
          <Form form={form} onFinish={onSubmit} className='add-childs__form '>
            <div className='add-childs__editImage   mt-4 mb-2'>
              <Form.Item
                key={i18n.language}
                name='avatarId'
                type='text'
                rules={[
                  {
                    required: false,
                  },
                  () => ({
                    validator(_, value) {
                      if (avatarId) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("chooseAvatar")));
                    },
                  }),
                ]}
              >
                <figure
                  onClick={() => {
                    dispatch(addChildData(allValues));
                  }}
                  className={`add-childs__figure  m-auto  ${mode === "edit" ? "show-edit " : ""
                    }`}
                  onMouseOver={
                    mode === "add" && !location?.state?.avatarPath?.length > 0
                      ? null
                      : () => setIsHovered(true)
                  }
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <Link
                    to={isParent ? ROUTES.SELECT_AVATAR : ROUTES.SELECT_GENDER}
                    state={{
                      mode,
                      isParent,
                      from,
                    }}
                  >
                    <img
                      className='add-childs__img'
                      src={
                        mode === "add"
                          ? location?.state?.avatarPath
                            ? addTokenToMedia(location?.state?.avatarPath)
                            : editPencil
                          : location?.state?.user?.avatar
                            ? addTokenToMedia(
                              baseUrl + location.state.user.avatar,
                            )
                            : addTokenToMedia(location.state.avatarPath)
                      }
                      alt='avatar'
                    />
                    {mode === "add" &&
                      !location?.state?.avatarPath?.length > 0 ? (
                      <></>
                    ) : (
                      <img className={`edit-icon`} src={editIcon} alt='' />
                    )}
                  </Link>
                </figure>
              </Form.Item>
              {mode === "add" && (
                <div className='add-childs__inputError--avatar'>
                  {avatarError}
                </div>
              )}
            </div>
            <div className='add-childs__form-item'>
              <Form.Item
                key={i18n.language}
                name='name'
                type='text'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const nameArr = value?.split(" ");
                      const regex = /^[a-zA-Z\u0600-\u06FF\s]*$/; // Regular expression to match only letters and spaces
                      if (!value) {
                        return Promise.reject(t("provideChildName"));
                      } else if (nameArr?.length && value.length) {
                        if (
                          nameArr?.length > 1 &&
                          nameArr[0]?.length !== 0 &&
                          nameArr[1]?.length !== 0
                        ) {
                          if (value.length > 20) {
                            return Promise.reject(t("maximumLength20"));
                          }
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                        // return Promise.reject(t("provideFullName"));
                      } else if (value.length > 20) {
                        return Promise.reject(t("maximumLength20"));
                      } else if (!regex.test(value)) {
                        return Promise.reject(t("enterValidName"));
                      } else {
                        return Promise.reject(t("provideChildName"));
                      }
                    },
                  }),
                ]}
              >
                <Input placeholder={t("name")} onKeyPress={preventNumbers} />
              </Form.Item>
            </div>
            {(mode === "add" || "edite") && !isParent && (
              <>
                <div className='add-childs__form-item'>
                  <InputField
                    name='age'
                    itemClassName='add-childs__form-item'
                    inputType={"number"}
                    min={0}
                    max={99}
                    maxLength={2}
                    placeholder={t("age")}
                    rules={[]}
                    onKeyPress={preventMinus}
                    inputClassName='w-100'
                  />
                  {/* <Form.Item key={i18n.language} name="age">
                    <Input
                      className="form-item-input"
                      maxLength={2}
                      type="number"
                      placeholder={t("age")}
                      onKeyDown={handleOnKeyDown}
                      // onChange={handleAgeOnChange}
                      // value={agee}
                    />
                  </Form.Item> */}
                </div>
                {/* language */}
                <div className='add-childs__form-item'>
                  <Form.Item key={i18n.language} name='language'>
                    <Select
                      placeholder={t("selectLang")}
                      className={
                        lang === "ar" ? "ant-select-add-child-language" : ""
                      }
                    >
                      {allLanguages?.map(({ id, name }) => (
                        <Select.Option value={id} key={id}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </>
            )}
            <div className='text-center mt-5'>
              <Button
                loading={loading}
                className='button-primary button-primary--add-child'
                htmlType='submit'
                type='primary'
              >
                {mode === "edit" ? t("saveChanges") : t("addChild")}
              </Button>
            </div>
          </Form>
        </Container>
      ) : (
        <div className='d-flex justify-content-center align-items-center flex-1'>
          <Spinner />
        </div>
      )}
    </section>
  );
};

export default AddChilds;

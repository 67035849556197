import { useState, useEffect, useRef, useReducer } from "react";
import {
  Button,
  Input,
  Dropdown as AntDropdown,
  Space,
  AutoComplete,
} from "antd";
import { Container, Dropdown, Navbar } from "react-bootstrap";
//import i18next from "localization/i18next";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLang } from "store/lang";
import { Image } from "react-image-and-background-image-fade";
import amuseLogo from "assets/images/amuse-logo.svg";
import localizationAr from "assets/icons/ar.svg";
import localizationEn from "assets/icons/localization-en.svg";
import search from "assets/icons/search.svg";
import searchWhite from "assets/icons/search-white.svg";
import NoResultsIcon from "assets/icons/NoResultsIcon.svg";
import ROUTES, { pagesNotHaveNavbar } from "constants/_routes";
import { baseUrl, getCurrentLanguageToService } from "services";
import { LANG_PARAMS } from "localization/translation-params";
import { changeLanguage, getSearchResults } from "./network";
import SignInModal from "./SignInModal";
import Skeleton from "react-loading-skeleton";
import gift from "../../assets/animated-images/gifts.json";
import SubscripeNowModal from "modules/Subscription/models/SubscripeNowModal/SubscripeNowModal";
import GifPlayer from "react-gif-player";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { onLangChange, toastError } from "helpers/toasters";
import LogoutModal from "./LogoutModal";
import { addTokenToMedia } from "helpers/addTokenToMedia";
import JoinToCompetitionModal from "./JoinToCompetitionModal";
import noInternetRedIcon from "../../assets/images/lostConntionIcon.svg";
import lostConntionIcon from "../../assets/images/lostConntionIcon.svg";
import downloadIcon from "../../assets/icons/downloadIcon.svg";
import binIcon from "../../assets/icons/binIcon.svg";
import bw10 from "../../assets/video-controllers/bw10.svg";
import fw10 from "../../assets/video-controllers/fw10.svg";
import replayIcon from "../../assets/video-controllers/replay-icon.svg";
import pause from "../../assets/video-controllers/pause.svg";
import play from "../../assets/video-controllers/play.svg";
import next from "../../assets/video-controllers/next.svg";
import audio from "../../assets/icons/audio.png";
import audioIcon from "../../assets/images/audio.svg";
import ccIcon from "../../assets/icons/cc.png";
import cc from "../../assets/video-controllers/cc.svg";
import setting from "../../assets/video-controllers/setting.svg";
import volumeUpIcon from "../../assets/video-controllers/volume-up.svg";
import muteIcon from "../../assets/video-controllers/mute.svg";
import FullscreenMaximizeIcon from "../../assets/video-controllers/Fullscreen-maximize.svg";
import FullscreenMinimizeIcon from "../../assets/video-controllers/Fullscreen-minimize.svg";
import FullscreenIcon from "../../assets/video-controllers/fullscreen.svg";
import notFoundIcon from "../../assets/images/not-found.svg";
import downloadedRegular from "../../assets/icons/downloaded_regular.svg";
import downloadedHover from "../../assets/icons/downloaded_hover.svg";
import plusIcon from "../../assets/icons/plus-button.svg";
import searchIcon from "../../assets/icons/search.svg";
import facebookIcon from "../../assets/icons/facebook.svg";
import instagramIcon from "../../assets/icons/instagram.svg";
import tikTokIcon from "../../assets/icons/tiktok.svg";
import twitterIcon from "../../assets/icons/twitter.svg";
import { toast } from "react-toastify";
import { getUserData } from "modules/Authentications/network";
import { changeAuthData } from "store/authorization";
import SkeletonAvatar from "antd/lib/skeleton/Avatar";
// import giftIcon from "assets/giftIcon.gif";
// import giftIcon from "assets/images/giftIcon-ezgif.com-crop.gif";
import giftIcon from "assets/images/giftIcon.gif";

function NewNavbar() {
  const location = useLocation();
  const { lang } = useSelector((state) => state.lang);
  const { t, i18n } = useTranslation();
  const { authorization } = useSelector((state) => state.auth);
  const [avatarImg, setavatarSrc] = useState(null);
  const [avatarLoading, setavatarLoading] = useState(false);
  const avatarDetailsAuth = authorization?.userAccounts
    ? authorization?.userAccounts.find(
        (item) => item?.id === authorization?.online?.["id"],
      )
    : null;
  useEffect(() => {
    if (!navigator.onLine) {
      setavatarSrc(authorization?.online?.avatar);
    }
    if (authorization?._id && navigator.onLine) {
      setavatarLoading(true);
      getUserData(
        { userId: authorization._id },
        (res) => {
          dispatch(changeAuthData({ userAccounts: res.data.userAccounts }));
          const avatarDetails = res.data.userAccounts
            ? res.data.userAccounts.find(
                (item) => item?.id === authorization?.online?.["id"],
              )
            : null;
          setavatarSrc(avatarDetails.avatar);
          setavatarLoading(false);
        },
        (fail) => {
          if (fail.data) toastError(fail.data.err_msg);
          else toastError(t("anErrorOccured"));
        },
      );
    }
  }, [
    avatarDetailsAuth?.avatar,
    authorization?.userAccounts?.length,
    authorization._id,
    authorization?.online?.["id"],
  ]);

  // const avatarImg = avatarDetails?.avatar;

  const [reset, updateReset] = useState(false);
  const isAuth = authorization?.token;
  const isPaid = authorization?.isPaid?.isPaid;
  const hasPaidBefore = authorization?.isPaid?.hasPaidBefore;
  // const isEntered = authorization?.isEnteredUserAccount;
  // const isPaidBefore = authorization?.isPaid?.hasPaidBefore;
  // const [searchBar, setSearchBar] = useState(true);
  const [searchBarStatus, toggleSearchBarStatus] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [searchResults, updateSearchResults] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const [isModalVisible, toggleModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isSubscribeNowModalVisible, updateIsSubscribeNowModalVisible] =
    useState(false);
  const [notInHome, setNotInHome] = useState(location.pathname === "/");
  const [whiteNavbar, setWhiteNavbar] = useState(!location.pathname === "/");
  const [dimentions, setDimentions] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const navbarRef = useRef();
  const [windowMode, setWindowMode] = useState(
    window.innerWidth > 991
      ? "lg"
      : window.innerWidth >= 768 && window.innerWidth <= 991
      ? "tablet"
      : "mobile",
  );
  const [isLogoutModalVisible, toggleLogoutModalVisible] = useState(false);
  const [joinToCompetitionModalVisible, setJoinToCompetitionModalVisible] =
    useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsedInSmallScreen, setIsCollapsedInSmallScreen] =
    useState(true);
  const closeJoinCompetitionHandler = () => {
    setJoinToCompetitionModalVisible(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navbar = useRef(null);
  const wrapperRef = useRef(null);
  const collapseBtn = useRef(null);
  const collapseArea = useRef(null);
  const collapseMobileNavbarRef = useRef(null);
  const [collapsed, setCollapsed] = useState(true);

  const cachingAssets = [
    lostConntionIcon,
    downloadIcon,
    binIcon,
    bw10,
    fw10,
    replayIcon,
    pause,
    play,
    next,
    audio,
    audioIcon,
    ccIcon,
    cc,
    setting,
    volumeUpIcon,
    muteIcon,
    FullscreenMaximizeIcon,
    FullscreenMinimizeIcon,
    FullscreenIcon,
    notFoundIcon,
    downloadedRegular,
    downloadedHover,
    plusIcon,
    searchIcon,
    facebookIcon,
    instagramIcon,
    tikTokIcon,
    twitterIcon,
    giftIcon,
    localizationAr,
    localizationEn,
  ];
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // useEffect(() => {
  //   if (isCollapsedInSmallScreen) {
  //     let rootDiv = document.getElementById("root");

  //     rootDiv.style.marginTop = "78px";
  //   }
  // }, [isCollapsedInSmallScreen]);

  const handleSubscribeBtn = (e) => {
    if (!navigator.onLine) {
      e.preventDefault();
      alert(t("YouareCurrentlyOfflinePleaseCheckYourInternetConnection"));
      return;
    }

    if (!isAuth) {
      document.body.style.overflow = "hidden";
      updateIsSubscribeNowModalVisible(true);
    }

    if (isAuth && authorization?.online?.isParent) {
      if (windowMode !== "lg") {
        handleToggle();
        handleCollapseMobileNavbar();
        navigate(ROUTES.SUBSCRIPTION);
      }
      navigate(ROUTES.SUBSCRIPTION);
    }

    if (isAuth && !authorization?.online?.isParent) {
      document.body.style.overflow = "hidden";
      updateIsSubscribeNowModalVisible(true);
    }
  };
  useEffect(() => {
    const detectWindowSize = () => {
      setDimentions({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
      if (window.innerWidth > 991) {
        setWindowMode("lg");
      } else if (window.innerWidth >= 768 && window.innerWidth <= 991) {
        setWindowMode("tablet");
      } else {
        setWindowMode("mobile");
      }
    };
    window.addEventListener("resize", detectWindowSize);
    return () => {
      window.removeEventListener("resize", detectWindowSize);
    };
  }, [dimentions]);

  useEffect(() => {
    if (location.pathname === "/") {
      setNotInHome(false);
    } else setNotInHome(true);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/") {
      document.getElementById("root").style.marginBlock = 0 + "px";
      window.addEventListener("scroll", onScrollHome);
    } else {
      document.getElementById("root").style.marginTop =
        pagesNotHaveNavbar.includes(location.pathname)
          ? 0 + "px"
          : navbar?.current?.clientHeight + 5 + "px";
      window.removeEventListener("scroll", onScrollHome);
      setWhiteNavbar(true);
    }
    return () => {
      window.removeEventListener("scroll", onScrollHome);
    };
    // eslint-disable-next-line
  }, [location.pathname]);
  useEffect(() => {
    if (
      location.pathname !== ROUTES.HOME &&
      location.pathname !== ROUTES.BANNER
    ) {
      setWhiteNavbar(true);
    } else {
      setWhiteNavbar(false);
    }
  }, [location]);

  useEffect(() => {
    if (searchValue.length) {
      onSearchValueChange();
    }
    // eslint-disable-next-line
  }, [searchValue]);

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      }
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
      };
    }, [ref, handler]);
  }

  const handleCollapseMobileNavbar = () => {
    // setExpanded(false);

    collapseBtn.current.className = "navbar-toggler collapsed";
    collapseArea.current.className = "navbar-collapse collapse";
    // setIsCollapsedInSmallScreen((prev) => !prev);
  };
  useOnClickOutside(collapseMobileNavbarRef, handleCollapseMobileNavbar);
  useOnClickOutside(wrapperRef, () => {
    handleColapseSearchBar();
  });

  const handleColapseSearchBar = () => {
    setSearchValue("");
    setTimeout(() => {
      toggleSearchBarStatus(false);
    }, 500);
  };

  const openGiftModalHandler = (e) => {
    if (!navigator.onLine) {
      e.preventDefault();
      alert(t("YouareCurrentlyOfflinePleaseCheckYourInternetConnection"));
      return;
    }
    document.body.style.overflow = "hidden";
    setJoinToCompetitionModalVisible(true);
  };
  const onSearchMovieClick = ({ key }) => {
    if (!navigator.onLine) return;

    if (isAuth) {
      const selectedVideoDetails = searchResults.filter(
        (result) => result?.key === key,
      );

      if (selectedVideoDetails?.length > 0) {
        let data =
          selectedVideoDetails[0].video.videoHeaderId +
          "-" +
          selectedVideoDetails[0].video._id +
          "-" +
          (selectedVideoDetails[0].video.isSeries ? 1 : 0);

        const watchNowDetails = {
          qp: data,
        };

        if (isPaid || selectedVideoDetails[0]?.video?.isFree) {
          navigate({
            pathname: ROUTES.WATCH_NOW,
            search: `?${createSearchParams(watchNowDetails)}`,
          });
          setSearchValue("");
        } else {
          updateIsSubscribeNowModalVisible(true);
          document.body.style.overflow = "hidden";
          setSearchValue("");
        }
      }
    } else {
      toggleModalVisible(true);
      setSearchValue("");
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: gift,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function Box({ children }) {
    return (
      <div className='d-flex align-items-center' style={{ height: "100px" }}>
        {children}
      </div>
    );
  }

  /*______________ OLD Logic ______________*/
  const onSearchValueChange = () => {
    let data = {
      lang,
      search: searchValue,
      userAccountId: "",
    };
    const loadingNode = [
      {
        key: 1,
        label: (
          <div className='text-center no-results-found'>
            <Skeleton count={3} wrapper={Box} height={100} />
          </div>
        ),
      },
    ];
    updateSearchResults(loadingNode);
    getSearchResults(
      data,
      (success) => {
        let AllVideos = success.data;
        const newVideosArr = [];
        AllVideos.forEach((video) => {
          newVideosArr.push({
            key: video?._id,
            video,
            label: (
              <div className='d-flex align-items-center'>
                <img
                  src={baseUrl + video?.imgPath}
                  alt='movie-img'
                  className='movie-cover'
                />
                <div className='title mx-2'>{video?.title}</div>
              </div>
            ),
          });
        });
        if (newVideosArr?.length) {
          setNoResult(false);
          updateSearchResults(newVideosArr);
        } else {
          setNoResult(true);
          const noResultsNode = [
            {
              key: 1,
              label: (
                <div className='text-center no-results-found'>
                  <div className='title m-2'>{t("noDataFound")}</div>
                  <img src={NoResultsIcon} alt='no-result' className='my-2' />
                </div>
              ),
              disabled: true,
            },
          ];
          updateSearchResults(noResultsNode);
        }
      },
      (fail) => {},
    );
  };

  function changeLangAction() {
    if (authorization?.online?.id) {
      let data = {
        accountId: authorization?.online?.id,
        lang: lang === "en" ? "ar" : "en",
        accountAppLanguage: lang === "en" ? "ar" : "en",
      };
      if (navigator.onLine) {
        changeLanguage(
          data,
          (success) => {},
          (fail) => {},
        );
      }
    }

    i18n.changeLanguage(lang);
    dispatch(changeLang(lang === "en" ? "ar" : "en"));
    getCurrentLanguageToService(lang);
  }

  useEffect(() => {
    onLangChange(lang === "ar");
  }, [lang]);

  function onScrollHome() {
    if (location.pathname === "/") {
      if (window.scrollY >= navbar?.current?.clientHeight) {
        setWhiteNavbar(true);
      } else setWhiteNavbar(false);
      // reset because of not in home
    } else {
      setWhiteNavbar(false);
    }
  }

  const handleClickOnGifts = () => {
    if (isAuth) {
      navigate(ROUTES.GIFTS);
    } else {
      toggleModalVisible(true);
    }
  };

  function getPopupContainer() {
    return wrapperRef.current;
  }

  const goTo = (path) => {
    handleCollapseMobileNavbar();
    navigate(path);
  };

  if (pagesNotHaveNavbar.includes(location.pathname)) return <></>;
  if (location.pathname?.includes(ROUTES.ADD_CHILDS)) return <></>;

  return (
    <Navbar
      ref={navbar}
      collapseOnSelect
      expand='md'
      expanded={expanded}
      onToggle={handleToggle}
      className={`amuse-nav  ${
        whiteNavbar ? "amuse-nav--white" : "navbar-dark"
      } ${notInHome ? "amuse-nav--white" : ""}`}
    >
      {/* this img only for caching it for using it when no internet */}
      {cachingAssets.map((asset) => (
        <img
          key={asset}
          src={asset}
          alt=''
          style={{ position: "absolute", width: "0", height: "0" }}
        />
      ))}

      <Container>
        {windowMode === "lg" && (
          <>
            <Navbar.Brand
              onClick={() => {
                updateReset((prevValue) => !prevValue);
              }}
            >
              <Link to={{ pathname: ROUTES.HOME }} state={{ reset }}>
                <img src={amuseLogo} alt='amuse the kids' />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <div className='amuse-nav__menus me-auto'>
                <ul className='amuse-nav__ul-center'>
                  <li className='item'>
                    <Link to={ROUTES.HOME} className='item'>
                      {t("home")}
                    </Link>
                  </li>
                  {authorization?.online ? (
                    <li className='item'>
                      <Link
                        to={ROUTES.MY_LIST}
                        onClick={(e) => {
                          if (!navigator.onLine) {
                            e.preventDefault();
                            alert(
                              t(
                                "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                              ),
                            );
                            return;
                          }
                        }}
                      >
                        {t("wishlist")}
                      </Link>
                    </li>
                  ) : (
                    <li className='item'>
                      <Link to={ROUTES.SIGNIN}>{t("signin")}</Link>
                    </li>
                  )}
                </ul>
                <ul
                  className={`amuse-nav__ul-end ${
                    searchBarStatus ? "d-flex flex-1" : ""
                  }`}
                  ref={wrapperRef}
                >
                  <li className={"item d-flex flex-1 flex-column"}>
                    <AntDropdown
                      menu={{
                        items: searchResults,
                        onClick: onSearchMovieClick,
                        // onClick: noResult ? null : onSearchMovieClick,
                      }}
                      overlayClassName='navbar-search-dropdown py-2'
                      open={searchValue?.length > 0 && searchBarStatus}
                      getPopupContainer={getPopupContainer}
                    >
                      <div
                        className={`${
                          searchBarStatus
                            ? "amuse-nav__search-container d-flex flex-1 mx-2 px-2 py-1"
                            : ""
                        }`}
                      >
                        <Input
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                          type='search'
                          className={`${
                            searchBarStatus ? "d-block" : "d-none"
                          }`}
                        />

                        <img
                          src={
                            whiteNavbar || searchBarStatus
                              ? search
                              : searchWhite
                          }
                          alt={"search in amuse"}
                          className={`search-icon`}
                          role='button'
                          onClick={(e) => {
                            if (!navigator.onLine) {
                              e.preventDefault();
                              alert(
                                t(
                                  "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                ),
                              );
                              return;
                            } else {
                              toggleSearchBarStatus(true);
                            }
                          }}
                        />
                      </div>
                    </AntDropdown>
                  </li>

                  {authorization?.online?.id && (
                    <>
                      <li className='item'>
                        <Dropdown
                          className={`amuse-nav__menu ${
                            whiteNavbar ? "white-nav" : ""
                          }`}
                        >
                          <Dropdown.Toggle
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className='item'>
                              {avatarLoading ? (
                                <SkeletonAvatar active size='large' /> // Placeholder for the avatar
                              ) : (
                                <img
                                  className='amuse-nav__avatar'
                                  src={addTokenToMedia(baseUrl + avatarImg)}
                                  alt='avatar'
                                />
                              )}
                            </div>
                            {/* {t(LANG_PARAMS.manageAccount)} */}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className={`
                               ${lang === "ar" ? "dropdown-menu-item-ar" : ""} 
                              `}
                              onClick={(e) => {
                                if (!navigator.onLine) {
                                  e.preventDefault();
                                  alert(
                                    t(
                                      "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                    ),
                                  );
                                  return;
                                } else {
                                  navigate(ROUTES.WATCHERS);
                                }
                              }}
                            >
                              {t(LANG_PARAMS.switchProfile)}
                            </Dropdown.Item>

                            {authorization?.online?.isParent && (
                              <Dropdown.Item
                                className={`
                               ${lang === "ar" ? "dropdown-menu-item-ar" : ""} 
                              `}
                                onClick={(e) => {
                                  if (!navigator.onLine) {
                                    e.preventDefault();
                                    alert(
                                      t(
                                        "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                      ),
                                    );
                                    return;
                                  } else {
                                    navigate(ROUTES.ACCOUNT_PROFILE);
                                  }
                                }}
                              >
                                {t("profile")}
                              </Dropdown.Item>
                            )}

                            <Dropdown.Item
                              className={`${
                                lang === "ar" ? "dropdown-menu-item-ar" : ""
                              }`}
                              onClick={() => navigate(ROUTES.MY_DOWNLOADS)}
                            >
                              {t("myDownloads")}
                            </Dropdown.Item>

                            <Dropdown.Item
                              className={`
                               ${lang === "ar" ? "dropdown-menu-item-ar" : ""} 
                              `}
                              onClick={(e) => {
                                if (!navigator.onLine) {
                                  e.preventDefault();
                                  alert(
                                    t(
                                      "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                    ),
                                  );
                                  return;
                                } else {
                                  document.body.style.overflow = "hidden";
                                  toggleLogoutModalVisible(true);
                                }
                              }}
                            >
                              {t("logout")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </>
                  )}
                  <ul className='amuse-nav-btnicongift-container'>
                    <li className='item item-btn'>
                      {!(hasPaidBefore && isPaid) && (
                        <Button
                          className='amuse-nav__btn button-primary'
                          onClick={handleSubscribeBtn}
                        >
                          {
                            hasPaidBefore
                              ? t("renewSubscription") // hasPaidBefore is true and isPaid is false
                              : t("subscribeNowWithout") // hasPaidBefore is false and isPaid is either true or false
                          }
                        </Button>
                      )}
                    </li>
                    {navigator.onLine && (
                      <li className='item item-gift'>
                        <div onClick={openGiftModalHandler}>
                          <GifPlayer gif={giftIcon} autoplay />
                        </div>
                      </li>
                    )}
                    <li className='item-lang'>
                      {lang === "en" ? (
                        <img
                          className={`${lang === "en"}`}
                          src={localizationAr}
                          alt='localization-ar'
                          role={"button"}
                          onClick={changeLangAction}
                        />
                      ) : (
                        <img
                          src={localizationEn}
                          alt='localization-en'
                          role={"button"}
                          onClick={changeLangAction}
                        />
                      )}
                    </li>
                  </ul>
                </ul>
              </div>
            </Navbar.Collapse>
          </>
        )}
        {windowMode === "tablet" && (
          <>
            {searchBarStatus ? (
              ""
            ) : (
              <Navbar.Brand href='#home'>
                <Link to={ROUTES.HOME}>
                  <img src={amuseLogo} alt='amuse the kids' />
                </Link>
              </Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <div className='amuse-nav__menus me-auto'>
                {searchBarStatus ? (
                  ""
                ) : (
                  <ul className='amuse-nav__ul-center'>
                    <li className='item'>
                      <Link to={ROUTES.HOME}>{t("home")}</Link>
                    </li>
                    {isAuth ? (
                      <li className='item'>
                        <Link
                          to={ROUTES.MY_LIST}
                          onClick={(e) => {
                            if (!navigator.onLine) {
                              e.preventDefault();
                              alert(
                                t(
                                  "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                ),
                              );
                              return;
                            }
                          }}
                        >
                          {t("wishlist")}
                        </Link>
                      </li>
                    ) : (
                      <li className='item'>
                        <Link to={ROUTES.SIGNIN}>{t("signin")}</Link>
                      </li>
                    )}
                  </ul>
                )}

                <ul
                  className={`amuse-nav__ul-end ${
                    searchBarStatus ? "d-flex flex-1" : ""
                  }`}
                  ref={wrapperRef}
                >
                  <li className={"item d-flex flex-1 flex-column"}>
                    <AntDropdown
                      menu={{
                        items: searchResults,
                        onClick: onSearchMovieClick,
                        // onClick: noResult ? null : onSearchMovieClick,
                      }}
                      overlayClassName='navbar-search-dropdown py-2'
                      open={searchValue?.length > 0 && searchBarStatus}
                      getPopupContainer={getPopupContainer}
                    >
                      <div
                        className={`${
                          searchBarStatus
                            ? "amuse-nav__search-container d-flex flex-1 mx-2 px-2 py-1"
                            : ""
                        }`}
                      >
                        <Input
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                          type='search'
                          className={`${
                            searchBarStatus ? "d-block" : "d-none"
                          }`}
                        />

                        <img
                          src={
                            whiteNavbar || searchBarStatus
                              ? search
                              : searchWhite
                          }
                          alt={"search in amuse"}
                          className={`search-icon`}
                          role='button'
                          onClick={() => toggleSearchBarStatus(true)}
                        />
                      </div>
                    </AntDropdown>
                  </li>

                  {authorization?.online?.id && (
                    <>
                      <li className='item'>
                        <Dropdown
                          className={`amuse-nav__menu ${
                            whiteNavbar ? "white-nav" : ""
                          }`}
                        >
                          <Dropdown.Toggle
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* {t(LANG_PARAMS.manageAccount)} */}
                            <div className='item'>
                              <Image
                                wrapperClassName='amuse-nav__avatar'
                                src={addTokenToMedia(baseUrl + avatarImg)}
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                if (!navigator.onLine) {
                                  e.preventDefault();
                                  alert(
                                    t(
                                      "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                    ),
                                  );
                                  return;
                                } else {
                                  navigate(ROUTES.WATCHERS);
                                }
                              }}
                            >
                              {t(LANG_PARAMS.switchProfile)}
                            </Dropdown.Item>

                            {authorization?.online?.isParent && (
                              <Dropdown.Item
                                onClick={() => navigate(ROUTES.ACCOUNT_PROFILE)}
                              >
                                {t("profile")}
                              </Dropdown.Item>
                            )}

                            <Dropdown.Item
                              className={`${
                                lang === "ar" ? "dropdown-menu-item-ar" : ""
                              }`}
                              onClick={() => navigate(ROUTES.MY_DOWNLOADS)}
                            >
                              {t("myDownloads")}
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => {
                                document.body.style.overflow = "hidden";
                                toggleLogoutModalVisible(true);
                              }}
                            >
                              {t("logout")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </>
                  )}

                  <ul className='amuse-nav-btnicongift-container'>
                    <li className='item item-btn'>
                      {!(hasPaidBefore && isPaid) && (
                        <Button
                          className='amuse-nav__btn button-primary'
                          onClick={handleSubscribeBtn}
                        >
                          {
                            hasPaidBefore
                              ? t("renewSubscription") // hasPaidBefore is true and isPaid is false
                              : t("subscribeNowWithout") // hasPaidBefore is false and isPaid is either true or false
                          }
                        </Button>
                      )}
                    </li>
                    {navigator.onLine && (
                      <li className='item item-gift'>
                        <div onClick={openGiftModalHandler}>
                          <GifPlayer gif={giftIcon} autoplay />
                        </div>
                      </li>
                    )}
                    <li className='item-lang'>
                      {lang === "en" ? (
                        <img
                          className={`${lang === "en"}`}
                          src={localizationAr}
                          alt='localization-ar'
                          role={"button"}
                          onClick={changeLangAction}
                        />
                      ) : (
                        <img
                          src={localizationEn}
                          alt='localization-en'
                          role={"button"}
                          onClick={changeLangAction}
                        />
                      )}
                    </li>
                  </ul>
                </ul>
              </div>
            </Navbar.Collapse>
          </>
        )}
        {windowMode === "mobile" && (
          <>
            {searchBarStatus ? null : (
              <Navbar.Brand href='#home'>
                <Link to={ROUTES.HOME}>
                  <img src={amuseLogo} alt='amuse the kids' height='47px' />
                </Link>
              </Navbar.Brand>
            )}
            <div className='amuse-nav__search-gift-mobile ' ref={wrapperRef}>
              <div
                className={` item d-flex mx-3 ${searchBarStatus && "flex-1"} `}
              >
                <AntDropdown
                  menu={{
                    items: searchResults,
                    onClick: onSearchMovieClick,
                    // onClick: noResult ? null : onSearchMovieClick,
                  }}
                  overlayClassName='navbar-search-dropdown py-2'
                  open={searchValue?.length > 0 && searchBarStatus}
                  getPopupContainer={getPopupContainer}
                >
                  <div
                    className={`${
                      searchBarStatus
                        ? "amuse-nav__search-container d-flex flex-1 mx-2 px-2 py-1"
                        : ""
                    }`}
                  >
                    <Input
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      type='search'
                      className={`${searchBarStatus ? "d-block" : "d-none"}`}
                    />
                    <img
                      src={
                        whiteNavbar || searchBarStatus ? search : searchWhite
                      }
                      alt={"search in amuse"}
                      className={`search-icon`}
                      role='button'
                      onClick={() => toggleSearchBarStatus(true)}
                    />
                  </div>
                </AntDropdown>
              </div>
              {searchBarStatus ? null : (
                <div className='item'>
                  <div onClick={openGiftModalHandler}>
                    <GifPlayer gif={giftIcon} autoplay />
                  </div>
                </div>
              )}
            </div>
            <Navbar.Toggle
              // expanded={expanded}
              aria-controls='responsive-navbar-nav '
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
              // onClick={toggleNavbar}

              ref={collapseBtn}
              // onClick={() => {
              //   handleCollapseMobileNavbar();
              //
              //   setIsCollapsedInSmallScreen((prev) => !prev);
              // }}
            />

            <Navbar.Collapse id='responsive-navbar-nav' ref={collapseArea}>
              <div
                className='amuse-nav__menus-mobile'
                ref={collapseMobileNavbarRef}
              >
                <ul
                  className={`amuse-nav__ul-end-mobile py-4 ${
                    searchBarStatus ? "d-flex flex-1" : ""
                  }`}
                >
                  {authorization?.online?.id && (
                    <>
                      <li className='item'>
                        <Dropdown
                          className={`amuse-nav__menu ${
                            whiteNavbar ? "white-nav-mobile" : ""
                          }`}
                        >
                          <Dropdown.Toggle
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* {t(LANG_PARAMS.manageAccount)} */}
                            <div className='item'>
                              <img
                                className='amuse-nav__avatar'
                                src={addTokenToMedia(baseUrl + avatarImg)}
                                alt='avatar'
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                if (!navigator.onLine) {
                                  e.preventDefault();
                                  alert(
                                    t(
                                      "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                    ),
                                  );
                                  return;
                                } else {
                                  goTo(ROUTES.WATCHERS);
                                }
                              }}
                            >
                              {t(LANG_PARAMS.switchProfile)}
                            </Dropdown.Item>

                            {authorization?.online?.isParent && (
                              <Dropdown.Item
                                onClick={(e) => {
                                  if (!navigator.onLine) {
                                    e.preventDefault();
                                    alert(
                                      t(
                                        "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                      ),
                                    );
                                    return;
                                  } else {
                                    goTo(ROUTES.ACCOUNT_PROFILE);
                                  }
                                }}
                              >
                                {t("profile")}
                              </Dropdown.Item>
                            )}

                            <Dropdown.Item
                              className={`${
                                lang === "ar" ? "dropdown-menu-item-ar" : ""
                              }`}
                              onClick={() => navigate(ROUTES.MY_DOWNLOADS)}
                            >
                              {t("myDownloads")}
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={(e) => {
                                if (!navigator.onLine) {
                                  e.preventDefault();
                                  alert(
                                    t(
                                      "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                                    ),
                                  );
                                  return;
                                } else {
                                  document.body.style.overflow = "hidden";
                                  toggleLogoutModalVisible(true);
                                }
                              }}
                            >
                              {t("logout")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </>
                  )}

                  {/* subscription */}
                  <li className='item'>
                    {!(hasPaidBefore && isPaid) && (
                      <Button
                        className='amuse-nav__btn button-primary'
                        onClick={handleSubscribeBtn}
                      >
                        {
                          hasPaidBefore
                            ? t("renewSubscription") // hasPaidBefore is true and isPaid is false
                            : t("subscribeNowWithout") // hasPaidBefore is false and isPaid is either true or false
                        }
                      </Button>
                    )}
                  </li>

                  {isAuth ? (
                    <li className='item'>
                      <Link
                        to={ROUTES.MY_LIST}
                        onClick={(e) => {
                          if (!navigator.onLine) {
                            e.preventDefault();
                            alert(
                              t(
                                "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                              ),
                            );
                            return;
                          } else {
                            handleToggle();
                            handleCollapseMobileNavbar();
                          }
                        }}
                      >
                        {t("wishlist")}
                      </Link>
                    </li>
                  ) : (
                    // signin
                    <li className='item'>
                      <Link
                        to={ROUTES.SIGNIN}
                        onClick={(e) => {
                          if (!navigator.onLine) {
                            e.preventDefault();
                            alert(
                              t(
                                "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                              ),
                            );
                            return;
                          } else {
                            handleToggle();
                            handleCollapseMobileNavbar();
                          }
                        }}
                      >
                        <Button className='amuse-nav__btn button-secondary-mobile'>
                          {t("signin")}
                        </Button>
                      </Link>
                    </li>
                  )}
                  <li className='item '>
                    <Link
                      to={ROUTES.HOME}
                      onClick={(e) => {
                        if (!navigator.onLine) {
                          e.preventDefault();
                          alert(
                            t(
                              "YouareCurrentlyOfflinePleaseCheckYourInternetConnection",
                            ),
                          );
                          return;
                        } else {
                          handleToggle();
                          handleCollapseMobileNavbar();
                        }
                      }}
                    >
                      {t("home")}
                    </Link>
                  </li>
                  {/* lang */}

                  <li className='item'>
                    <Space size={10}>
                      {" "}
                      <span>Language</span>
                      {lang === "en" ? (
                        <img
                          className={`${lang === "en"}`}
                          src={localizationAr}
                          alt='localization-ar'
                          role={"button"}
                          onClick={changeLangAction}
                        />
                      ) : (
                        <img
                          src={localizationEn}
                          alt='localization-en'
                          role={"button"}
                          onClick={changeLangAction}
                        />
                      )}
                    </Space>
                  </li>
                </ul>
              </div>
            </Navbar.Collapse>
          </>
        )}
      </Container>
      <SignInModal
        isModalVisible={isModalVisible}
        onCancel={() => {
          toggleModalVisible(false);
        }}
      />

      <SubscripeNowModal
        isModalVisible={isSubscribeNowModalVisible}
        onCancel={() => {
          updateIsSubscribeNowModalVisible(false);
          document.body.style.overflow = "";
        }}
        // isParentOnline={authorization?.online?.isParent}
      />

      <LogoutModal
        isModalVisible={isLogoutModalVisible}
        onCancel={() => {
          document.body.style.overflow = "";
          toggleLogoutModalVisible(false);
        }}
      />
      <JoinToCompetitionModal
        isModalVisible={joinToCompetitionModalVisible}
        onCancel={() => {
          document.body.style.overflow = "";
          setJoinToCompetitionModalVisible(false);
        }}
      />
    </Navbar>
  );
}

export default NewNavbar;

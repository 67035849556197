import ROUTES from "constants/_routes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resendSubscriptionContractVerificationSMS,
  sendSmsToSubscribedUser,
  verifySubscriptionContract,
} from "./network";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { OTPInputComponent } from "modules/Authentications/components/OTPInput/Index";
import Countdown from "antd/lib/statistic/Countdown";
import { toastError } from "helpers/toasters";
import { startedTime } from "store/timeSlice";
import { Button } from "antd";

function TpayVerification() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lang, dir } = useSelector((state) => state.lang);
  const [loading, setLoading] = useState(false);
  const [lastVerificationTime, setLastVerificationTime] = useState(0);
  const [cancelNumber, setCancelNumber] = useState("");
  // const [showTimer, setShowTimer] = useState(true);
  const [disableSubmition, setDisableSubmition] = useState(false);
  // const [disableResendBtn, setDisableResendBtn] = useState(true);
  const [timer, setTimer] = useState(Date.now() + 1000 * 10 * 12);
  const [pinCode, setPinCode] = useState("");
  const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000)); // Initialize with the current time in seconds
  const timeDifference = currentTime - lastVerificationTime;
  const counterTimer = currentTime - timer / 1000;

  useEffect(() => {
    // if (counterTimer >= 0) {
    //   setDisableResendBtn(false);
    // } else {
    //   setDisableResendBtn(true);
    // }
    // if (timeDifference > 120 && timer / 1000 - currentTime > 0) {
    if (timeDifference > 120) {
      setDisableSubmition(false); // Enable verification button
    } else {
      setDisableSubmition(true); // Disable verification button
    }
  }, [timer, timeDifference, counterTimer]);

  const TIMER_STORAGE_KEY = "tpayTimer";
  useEffect(() => {
    // Retrieve the timer value from localStorage on component mount
    const storedTimer = localStorage.getItem(TIMER_STORAGE_KEY);
    if (storedTimer) {
      const parsedTimer = parseInt(storedTimer, 10);
      setTimer(parsedTimer); // Set the timer to the stored value
    }
  }, []);
  useEffect(() => {
    // Retrieve the last verification time from localStorage on component mount
    const storedLastVerificationTime = parseInt(
      localStorage.getItem("lastVerificationTime"),
      10,
    );
    if (storedLastVerificationTime) {
      setLastVerificationTime(storedLastVerificationTime);
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(Date.now() / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const welcomeMessage =
    t("welcome") +
    " " +
    location?.state?.phoneNumber +
    " " +
    t(
      `you are subscribed to Amuse competition. To cancel subscription send “STOP ATK“ to`,
    ) +
    " " +
    cancelNumber;

  const getCancelNumber = (code) => {
    let number = "";

    switch (code) {
      case "60201":
        number = "5030"; // Orange
        break;
      case "60202":
        number = "2303"; // Vodafone
        break;
      case "60203":
        number = "1722"; // Etisalat
        break;
      case "60204":
        number = "4041"; // WE
        break;
      default:
        // Handle other cases or invalid input
        break;
    }

    return number;
  };
  useEffect(() => {
    // Check if a timer value exists in localStorage
    setCancelNumber(getCancelNumber(location?.state?.operatorCode));
    const storedTimer = localStorage.getItem(TIMER_STORAGE_KEY);
    if (storedTimer) {
      const parsedTimer = parseInt(storedTimer, 10);
      const currentTime = Date.now();
      const timeDifference = parsedTimer - currentTime;

      if (timeDifference > 0) {
        setTimer(parsedTimer); // Set the timer to the stored value
      }
    }
  }, []); // Run this effect only once on component mount

  useEffect(() => {
    // Save the timer value in localStorage whenever it changes
    localStorage.setItem(TIMER_STORAGE_KEY, timer);
  }, [timer]);
  useEffect(() => {
    if (!location?.state?.productCatalog) {
      navigate(ROUTES.TPAYSUBSCRIPTION);
    }
  }, []);
  const handlePinCodeChange = (pincode) => {
    setPinCode(pincode);
  };
  // function onfinishTimer() {
  //   // setShowTimer(false);
  //   setDisableSubmition(true);
  // }
  const resendCode = () => {
    const payload = {
      subscriptionContractId: String(location.state.subscriptionContractId),
    };
    resendSubscriptionContractVerificationSMS(
      payload,
      (success) => {
        setTimer(Date.now() + 1000 * 10 * 12);
        // setShowTimer(true);
      },
      (fail) => {
        if (fail.data.err_msg) {
          toastError(fail.data.err_msg);
        }
      },
    );
  };

  const verifySubscriptionHandler = () => {
    if (loading || !pinCode || pinCode.length < 6) return;
    setLoading(true);
    if (timeDifference < 120) {
      // If the time difference is less than 120 seconds, prevent verification
      // You can display a message or handle it accordingly
      // For example:

      return;
    }
    const payload = {
      subscriptionContractId: location.state.subscriptionContractId,
      pinCode: pinCode,
      transactionId: location.state.transactionId,
      operatorCode: location.state.operatorCode,
    };
    const smsPayload = {
      operatorCode: String(location.state.operatorCode),
      subscriptionContractId: String(location.state.subscriptionContractId),
      messageBody: welcomeMessage,
    };
    verifySubscriptionContract(
      payload,
      (success) => {
        sendSmsToSubscribedUser(
          smsPayload,
          (success) => { },
          (fail) => { },
        );
        navigate(ROUTES.GIFTS, {
          state: {
            phoneNumberWithCode: `2${location.state.phoneNumber}`,
          },
        });
      },
      (fail) => {
        setLoading(false);

        setLastVerificationTime(currentTime);
        localStorage.setItem("lastVerificationTime", currentTime);
        dispatch(
          startedTime({
            timestart: Math.floor(Date.now() / 1000),
            phoneNumber: location.state.phoneNumber,
          }),
        );
        if (fail.data.err_msg) {
          toastError(t(fail.data.err_msg));
        }
      },
    );
  };

  return (
    <div className=' text-center main-section' style={{ marginTop: "4.75rem" }}>
      <div
        className='phoneinput_container'
        style={{
          marginBottom: "6.25rem",
          display: "flex",
          gap: "2.5rem",
        }}
      >
        <label
          className='selector_container_label'
          htmlFor='MSISDN'
          style={{ marginBottom: "0" }}
        >
          {t("EnterThePinCode")}
        </label>
        {/* <div className='box my-2'>
          {" "}
          <Countdown
            className='text-center auth__timer'
            value={timer}
            format='mm:ss'
            onFinish={onfinishTimer}
          />
        </div> */}
        <OTPInputComponent
          id='MSISDN'
          length={6}
          value={pinCode}
          autoFocus
          isNumberInput
          className='pinCodeContainer'
          inputClassName='PinCodeInput'
          // isSecure
          onChangeOTP={(pinCode) => {
            handlePinCodeChange(pinCode);
          }}
        />
        {timeDifference < 120 && (
          <p
            className='error-message error-message--verification'
            style={{ textAlign: dir == "ltr" ? "left" : "right" }}
          >
            {" "}
            {t(`Pleasewait`) +
              " " +
              `${120 - timeDifference}` +
              " " +
              t(
                `secondsbeforemakinganothersubscriptionrequesttothesamenumbertoavoidbeingblocked`,
              )}{" "}
          </p>
        )}
        {/* {period > 120 &&
          location.state.phoneNumber !== subscriptionRequest.phoneNumber && (
            <p
              className='error-message'
              style={{
                textAlign: dir === "ltr" ? "left" : "right",
                marginTop: "-1rem",
                fontSize: ".9rem",
                color: "rgba(0,0,0,.85)",
                fontWeight: "400",
              }}
            >
              {t(`Pleasewait`) +
                " " +
                `${120 - period}` +
                " " +
                t(
                  `secondsbeforemakinganothersubscriptionrequesttothesamenumbertoavoidbeingblocked`,
                )}
            </p>
          )} */}
        {/* <input
            type='text'
            id='MSISDN'
            // className={`selector_container-phoneinput ${
            //   phoneNumberError ? "error-input" : ""
            // }`}
            value={phoneNumber}
            onChange={phoneNumberhandler}
            placeholder='201XXXXXXXXX'
          /> */}
        {/* {phoneNumberError ? (
            <p className='error-message'>{phoneNumberError}</p>
          ) : (
            <p className='error-message'>&nbsp;</p>
          )} */}
      </div>
      <div>
        <Button
          type='button'
          className='subscribe-btn subscribe-btn_verify'
          onClick={verifySubscriptionHandler}
          disabled={disableSubmition || pinCode.length < 6}
          loading={loading}
        >
          {t("verify")}
        </Button>
        {/* <button
          type='button'
          className='subscribe-btn'
          // className='subscribe-btn subscribe-btn_joinCompetition'
          onClick={verifySubscriptionHandler}
          // disabled={disableSubmition}
        >
          {t("verify")}
        </button> */}
        <div className='auth__sub-text mb-4'>
          <span>{t("didntGetCode")}</span>
          <button
            // disabled={disableResendBtn}
            onClick={resendCode}
            style={{ border: "none", backgroundColor: "transparent" }}
            // className={`auth__forget-pin-code-resend-code ${
            //   disableResendBtn ? "resend-code-inActive" : ""
            // }`}
            className="auth__forget-pin-code-resend-code"
          >
            {t("resendOtpCode")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TpayVerification;

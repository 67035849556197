export const en = {
  signin: "Sign In",
  login: "Log In",
  signup: "Register",
  mobileNumber: "Mobile number",
  password: "Password",
  newPassword: "New password",
  forgetPassword: "Forget Password?",
  dontHaveAccount: "Don't have an account yet?",
  subscribeNow: "Subscribe now!",
  signupNow: "Sign up Now!",
  name: "Name",
  fullName: "Full Name",
  email: "E-mail",
  mobile: "Mobile Number",
  confirmPassword: "Confirm password",
  confirmNewPassword: "Confirm new password",
  passwordChangedSuccessfully: "password Changed Successfully",
  age: "Age",
  gender: "Gender",
  alreadySubscribe: "Already have an account?",
  provideName: "Please, enter your name",
  providePhoneNumber: "Please,enter your mobile number.",
  provideValidPhoneNumber: "Please, provide a valid mobile number",
  provideEmail: "Please, enter your email",
  provideMessage: "Please, provide your message",
  providePassword: "Please provide your password. At least 6 characters",
  passwordLength: "Please provide at least 6 characters.",
  provideConfirmPassword: "Please, confirm your password",
  provideValidEmail: "Please, provide a valid email.",
  confirmPasswordDoesntMatch: "Confirm password doesn't match",
  provideFullName: "Please, provide your full name",
  home: "Home",
  contactUs: "Contact Us",
  help: "Help",
  applications: "Applications",
  termsAndConditions: "Terms and Conditions",
  privacyPolicy: "Privacy Policy",
  rights: "All rights reserved © Amuse the Kidz 2021",
  forgetPasswordHeader: "Forget Password?",
  changePasswordHeader: "Change Password",
  enterMobileNumberToRecieveOTP:
    "Enter your mobile number to receive an OTP code",
  sendCode: "Send Code",
  rememberPassword: "Remember Password?",
  signinNow: "Log In Now!",
  verifyOtpCode: "OTP Code Verification",
  enterOtpNumber: "Enter the OTP code that you received in your mobile number",
  enter5digitsOtpNumber: "Enter the 5 digit code that sent to your number",
  otpCodeError: "Please, enter the correct OTP",
  forgetOtpCodeError: "Please, enter the correct code",
  reachedMaxNumPerDay:
    "Sorry! You have reached the maximum number of PIN resets for today. Please try again tomorrow.",
  emptyOtpCode: "Please, enter the OTP",
  didntGetCode: "Didn’t get code? ",
  didntReceiveCode: "Didn’t receive a code? ",
  resendCode: "Resend Code",
  resendOtpCode: "Resend Code",
  forgetPinCode: "Forget your pin?",
  personalInformation: "Personal Information",
  saveChanges: "Save Changes",
  chooseWhoWatchNow: "Select who is watching now",
  manageYourAccount: "Manage Profiles",
  selectAvatar: "Select Avatar",
  pay: "Pay",
  or: "Or",
  availableLang: "Available Languages",
  part: "Part",
  episode: "Episode",
  personalInfoEditSuccess: "Personal information updated successfully",
  accountCreatedSuccess: "Account created successfully",
  confirmCode: "Confirm",
  wishList: "My list",
  subscribeNowWithout: "Subscribe Now",
  renewSubscription: "Renew subscription",
  seeAll: "See All",
  manageYourAccounts: "Manage your accounts",
  Add: "Add",
  Parent: "Parent",
  Kid1: "Kid 1",
  Kid2: "Kid 2",
  myList: "My List",
  Send: "Send",
  YourMessage: "Your Message",
  ProvideAtLeastTenNumbers: "Provide at least 10 numbers",
  ProvideAtLeastElevenNumber: "Provide at least 11 number",
  MaximumNumbers11: "Please, maximum 11 number ",
  MaximumNumbers10: "Please, maximum 10 numbers ",
  resetPassword: "Reset Password",
  confirm: "Confirm",
  passwordResetedSuccessfully: "Password reset successfully",
  parentalControlCode: "Parental control code",
  editPinCode: "Edit the pin code",
  pinCode: "Pin code",
  verifyParentalControlCode: "Parental control code",
  createParentalCode: "Create 4 digit code to control your account",
  enterPinCode: "Enter 4 digit code to control your account",
  save: "Save",
  savedPercent: "Save",
  saved: "Saved",
  parentalCodeError: "Parental Code Error",
  pinCodeCreatedSuccess: "Parental Code Created Successfully",
  pinUpdateFaild: "Update Faild",
  successPinCode: "Success Parental Code",
  errorPinCode: "Error Parental Code",
  verify: "Verify Code",
  enter: "Enter",
  changePassword: "Change Password",
  changePersonalInformation: "Change Personal Information",
  editeParentalCode: "Edit Parental Code",
  history: "History",
  mySubscription: "My Subscription",
  oldPassword: "Old Password",
  watchNow: "Watch Now",
  provideNewPassword: "Please, Provide new password",
  editFourDigitCode: "Edit your pin code to control your account",
  pinCodeUpdatedSuccess: "Pin code updated successfully",
  noChanges: "No Changes",
  provideChildAge: "Provide child age",
  ageMustBePlus18: "Age must not be less than 18 ",
  provideChildName: "Please, enter your name",
  provideLanguage: "Please, select a language",
  fieldDoesNotAcceptNumbers: "Field dose not accept numbers",
  accountCreatedSuccessfully: "Account created successfully",
  addChild: "Add Child",
  chooseLanguage: "Choose Language",
  cast: "Cast",
  movieParts: "Movie Parts",
  episods: "Episodes",
  userDataUpdatedSuccessfully: "User’s data updated successfully",
  datasentSuccessfully: "Data sent successfully",
  search: "Search",
  manageAccount: "Manage Account",
  switchProfile: "Switch Profile",
  selectLang: "Select a language",
  addLetters: "Please type letters only",
  enterValidName: "Please enter a valid name",
  ageNotAllowed: "your age is not allowed",
  chooseAvatar: "Please choose an avatar",
  chooseGender: "Please choose gender",
  profile: "Profile Settings",
  logout: "Log Out",
  audio: "Audio",
  subtitles: "Subtitles",
  subtitle: "Subtitle",
  quality: "Quality",
  volume: "Volume",
  off: "Off",
  default: "default",
  tvshow: "Tv Shows",
  movies: "Movies",
  anime: "Anime",
  tvProgram: "Tv Programs",
  funStore: "Fun Store",
  filter: "Filter",
  oopsNotFound: "Ooops, Page not found!",
  lost_connection: "Ooops, We lost connection",
  backToHome: "Back to Home",
  enterValidCode: "Please, Enter a valid code.",
  haveReachedMaxCapcity: "You’ve Reached The Maximum Device Capacity!",
  alreadyHas4Devices:
    "You already have 4 devices logged in at the same time, If you want to log in you have to select one to log it out.",
  logItOut: "Log It Out",
  areYouSureYouWantToDeleteThisUser:
    "Are you sure you want to delete this child ?",
  addAvatar: "Add Avatar",
  editAvatar: "Edit Avatar",
  youAreA: "You are a",
  boy: "Boy",
  girl: "Girl",
  delete: "Delete",
  cancel: "Cancel",
  noDataFound: "Ooops, No data found!",
  noConnection: "Ooops, We lost connection",
  tryAgain: "Try again",
  signinTitle: "Please sign in or start your membership to get started!",
  subscibeTitle:
    "Enjoy Amuse the Kidz originals, exclusive series, movies and more",
  cantAddMoreThanFour: "Oops, You can’t add more than 5 accounts!",
  submit: "Submit",
  audios: "Audios",
  selectAnswer: "Please select an answer",
  choosePlan: "Choose Your Plan",
  month: "Month",
  startDate: "Start date:",
  endDate: "End date",
  from: "Start date:",
  to: "End date",
  proceedToPayment: "Proceed To Payment",
  selectPackage: "Please select a package",
  choosePaymentMethod: "Choose your payment method",
  total: "Total:",
  YourFawryCode: "Your Fawry Code is",
  paymentApproved: "Payment Approved",
  thanks: "Thank you for using the online payment service.",
  somethingWentWrong: "Something went wrong",
  pleaseTryAgain: "There is something wrong in your payment, Please try again",
  expirationDate: "*Expiration date is in",
  shareDigits:
    "Please share the 6-digital code with Fawry store to complete your payment.",
  subscriptionPlan: "Subscription Plan",
  myPlan: "My Plan",
  myCreditCards: "My Credit Cards",
  paymentDetails: "Payment Details",
  myPlanIs: "My plan is:",
  renewSubscriptionAutomatically: "Renew Subscription Automatically",
  changePlan: "Change Your Plan",
  AllPlans: "All Plans",
  chooseYourPlan: "Choose Your Plan",
  cardDetails: "My Card details:",
  noCard: "There is no credit card yet",
  expiresIn: "Expires in",
  makeDefaultCard: "Make it default card",
  deleteCardValidation:
    "Are you sure that you want to delete this credit card?",
  deleteCardNote: "If you click delete it will be removed perminantly",
  clearFilter: "Clear filter",
  pleaseSubscribeFirstParent:
    "To enjoy watching the content, you have to subscribe first",
  pleaseSubscribeFirstChild:
    "To enjoy watching the content you have to subscribe first through the parent account",
  sureToLogout: "Are you sure you want to log out?",
  pleaseLoginFirst: "Please sign in first to get started!",
  wishlist: "My list",
  myDownloads: "My Downloads",
  goToDownloads: "Go to Downloads",
  download: "Download",
  chooseToDownload: "To download this video, please choose:",
  ok: "Ok",
  offf: "Off",
  downloading: "Downloading...",
  downloadingSuccess: "Downloaded successfully!",
  noVideosInDownload: "No videos in your downloads yet",
  noVideosInList: "No videos in your list yet",
  goHomeToAddInDownloads:
    "Go to home to add more videos to your downloads list.",
  goHomeToAddInList: "Go to home to add more videos to your list now.",
  getTheApp: "Get the app",
  subscribeFirst: "To Enjoy Watching The Content You Have To Subscribe First!",
  loginFirst: "To Enjoy Watching The Content You Have To Sign In First!",
  soon: "Soon",
  noVedios:
    "We’re working on adding new videos and they’ll be ready very soon!",
  selectUser: "Select User",
  EGP: "EGP",
  freeDays: "free days",
  freeDay: "free day",
  changeMyPlanNote:
    "Note: If you subscribe to another package, it will start after the current one ends",
  auto: "Auto",
  hd: "HD",
  low: "Low",
  Getyouroffer: "Get Your Offer!",
  enteryourcode: "enter your code and enjoy watching",
  entercode: "Enter code",
  proceed: "Proceed",
  proceedwithcode: "Proceed With Code",
  emptycodemessage: "Please, Enter code",
  oldParentalCode: "Old Parental Code",
  next: "Next",
  LeaveCompetition: "Leave Competition",
  Chooseyournetworkoperator: "Please select the operator",
  choosetheoperator: "Choose the operator",
  JoiningCompetition: "Joining Competition",
  EnterYourPhoneNumber: "Enter Your Phone Number",
  subscribe: "subscribe",
  Enteravalidnumberstartingwith011012010or015:
    "Enter a valid number starting with 011, 012, 010, or 015",
  Phonenumbermustbeexactly11digits:
    "Phone number must be exactly 11 digits and start with 01",
  EnterThePinCode: "Enter The OTP Code that consisted of 6 digits ",
  verify: "verify",
  Subscriptioncosts2EGPday: "Subscription costs 2 EGP/day",
  Tocancel: "To cancel:",
  OrangesendStopSNto5030: "-  Orange, send “Stop ATK” to 5030",
  EtisalatsendStopSNto1722: "-  Etisalat, send “Stop ATK” to 1722",
  VodafonesendStopSNto2303: "-  Vodafone, send “Stop ATK” to 2303",
  wesendStopSNto4041: "-  we, send “Stop ATK” to 4041",
  vodafoneisnotsupportedyet: "vodafone is not supported yet",
  Incaseyoudonthaveenoughcredit:
    "In case you don’t have enough credit, it’s possible to collect subscription amount retroactively!",
  Arabic: "Arabic",
  English: "English",
  French: "French",
  downloaded: "Downloaded",
  Subscriptioncosts10EGPday: "Subscription costs 10 EGP/day",
  // Thisuseralreadysubscribedtothegivenproduct:
  //   "This user already subscribed to the given product",
  "This user already subscribed to the given product":
    "you are already subscribed to AmuseTheKidz service",
  "This mobile number is blacklisted": "This mobile number is blacklisted",
  WelcomeToAmuseTheKidzCompetition: "Welcome To AmuseTheKidz Competition",

  Pleaseenteranumberstartingwith012forOrange:
    "Please enter a number starting with 012 for Orange.",
  Pleaseenteranumberstartingwith010forVodafone:
    "Please enter a number starting with 010 for Vodafone.",
  Pleaseenteranumberstartingwith011forEtisalat:
    "Please enter a number starting with 011 for Etisalat.",
  Pleaseenteranumberstartingwith015forWE:
    "Please enter a number starting with 015 for WE.",
  Pleasewait: "Please wait",
  secondsbeforemakinganothersubscriptionrequesttothesamenumbertoavoidbeingblocked:
    "seconds before making another subscription request to the same number to avoid being blocked.",
  Vodafone: "Vodafone",
  We: "We",
  Etisalat: "Etisalat",
  Orange: "Orange",
  Yourmobilenumber: "Your mobile number ",
  isalreadysubscribedtoAmusecompetition:
    " is already subscribed to Amuse competition",
  Enteryourmobilenumbertoconfirmjoiningthecompetition:
    "Enter your mobile number to confirm joining the competition",
  Join: "Join",
  "you are subscriped to Amuse competition. To cancel subscription send “STOP ATK“ to":
    "you are subscribed to Amuse competition. To cancel subscription send “STOP ATK“ to",
  welcome: "Welcome",
  "Failed To Charge User":
    "There is not enough credit to complete registeration",
  "Failed To Send Verification SMS": "Failed To reSend Verification SMS",
  InvalidPinCodeOrPassword: "Invalid OTP Code",
  "you are already a subscripted user and can’t change your plan unless your current plan is ended or subscription is canceled.":
    "you are already a subscribed user and can’t change your plan unless your current plan is ended or subscription is canceled.",
  "To enjoy watching the content you have to subscribe first!":
    "To enjoy watching the content you have to subscribe first!",
  maximumLength20: "Maximum length is 20 characters",
  unavailableOffline: "Unavailable Offline",
  YouareCurrentlyOfflinePleaseCheckYourInternetConnection:
    "You are currently offline. Please check your internet connection.",
  enterOTPCode: "Please, enter the otp code",
  Sessionended: "Session ended. Try again!",
};

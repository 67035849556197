import { Popover, Space } from "antd";
import { useTranslation } from "react-i18next";

import { BsCheckLg } from "react-icons/bs";

const SettingItem = ({ item, videoFullScreenWrapper }) => {
  const { t, i18n } = useTranslation();

  const itemContent = (
    <ul className="setting-list-wrapper" key={item.id}>
      {item?.options?.map((option, index) => (
        <li
          className="setting-list-wrapper_item"
          key={option.id}
          onClick={() => item.setValue(index)}
          role="button"
        >
          {item.activeOption === index ? (
            <BsCheckLg size={20} style={{ marginInlineEnd: "0.5rem" }} />
          ) : null}
          {t(option.languageName)}
        </li>
      ))}
    </ul>
  );

  return (
    <Popover
      content={itemContent}
      placement="topLeft"
      title={item.title}
      key={item.id}
      // trigger="click"
      showArrow={false}
      getPopupContainer={() => videoFullScreenWrapper?.current}
      // overlayStyle={{ position: "fixed", zIndex: "99999" }}
    >
      <Space align="center" role="button">
        <span className="mock-block">
          <img src={item.icon} alt="" />
        </span>
        <span className="mock-block">{t(item.title)}</span>
      </Space>
    </Popover>
  );
};

export default SettingItem;

import React from "react";
// import noInternet from "../../assets/images/no-internet.svg";
import noInternetRedIcon from "../../assets/images/lostConntionIcon.svg";
import { Button } from "antd";
import amuseLogo from "../../assets/images/amuse-logo.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/_routes";
import { useSelector } from "react-redux";
function NoInternetPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authorization } = useSelector((state) => state?.auth);

  const handleGoToDownloads = () => {
    navigate(ROUTES.MY_DOWNLOADS, { replace: true });
  };
  const noConnectionTitle = {
    color: "#356AB3",
    fontSize: "25px",
    fontWeight: "700",
    lineHeight: "35px",
  };
  return (
    <div
      className='p-4 text-center'
      style={{ minHeight: "calc(100vh - 237px)" }}
    >
      <img src={amuseLogo} alt='amuseLogo' />
      <div style={noConnectionTitle} className='my-4 '>
        <div>{t("noConnection")}</div>
      </div>
      <img
        className='my-4 no-connection-image'
        src={noInternetRedIcon}
        alt='noInternet'
      />
      {authorization?.token && (
        <div className='text-center m-auto'>
          <Button className='button-primary blue' onClick={handleGoToDownloads}>
            {t("goToDownloads")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default NoInternetPage;

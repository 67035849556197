import { addTokenToMedia } from "helpers/addTokenToMedia";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import ROUTES from "constants/_routes";
import ReactPlayer from "react-player";

import { LoadingSpinner } from "./VideoComponents/LoadingSpinner";
import Controls from "./VideoComponents/Controls";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { detectInternetSpeed } from "helpers/DetectInternetSpeed";
import { checkVideoFree, continueWatching } from "../network";
import { useSelector } from "react-redux";
import {
  dbInfo,
  getSpecificDataFromDB,
  initializeDatabase,
} from "helpers/indexdb";
import SubscribenowModal from "./SubscribenowModal";
const VideoShowTest = ({
  videoData,
  relatedPartsData,
  seriesOrParts,
  getCurrentSeasonId,
  // isFree,
  isSubscribed,
}) => {
  const lastSeenTime = +videoData?.lastSeenTime;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  // Check if the 'isOffline' parameter exists and its value is 'true'
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const videoFullScreenWrapper = useRef();

  const audioElement = audioRef?.current?.getInternalPlayer();
  const videoElement = videoRef?.current?.getInternalPlayer();

  const { authorization } = useSelector((state) => state.auth);
  const isPaid = authorization?.isPaid?.isPaid;

  /*_____________Params______________*/
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const urlparams = new URLSearchParams(location.search);

  // const isOffline = isOfflineParam === "true";
  const isOffline = !urlparams.get("isOffline")
    ? false
    : urlparams.get("isOffline");
  // useEffect(() => {
  //   if (navigator.onLine) {
  //     searchParams.delete("isOffline");
  //     setSearchParams(searchParams);
  //   }
  // }, []);

  const [videoHeaderId, videoId, isSeries, showFullScreen] =
    params.qp.split("-");
  const [videoPath, setVideoPath] = useState("");

  const [audio, setAudio] = useState(0);
  const [subtitle, setSubtitle] = useState(0);
  const [quality, setQuality] = useState(0);
  const [allSubtitles, setAllSubtitles] = useState([]);
  const [showSubscribeNowModal, setshowSubscribeNowModal] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [videoIsReady, setVideoIsReady] = useState(false);

  const [mouseMoving, setMouseMoving] = useState(false);
  const [enableMove, setEnableMove] = useState(true);

  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [videoDurationTime, setVideoDurationTime] = useState(0);
  const [currentVideoBufferd, setCurrentVideoBufferd] = useState(0);
  const [isFree, setIsFree] = useState(false);

  const changeQualitytime = useRef(0);

  /* ________________check video is free______________________ */
  useEffect(() => {
    if (navigator.onLine) {
      checkVideoFree(videoId, (success) => {
        setIsFree(success.data.isFree);
        if (!success.data.isFree && !isPaid) setshowSubscribeNowModal(true);
      });
    }
  }, [videoId]);
  /* _________________SET offline/online __________________ */
  const [OfflineVideoURL, setOfflineVideoURL] = useState("");
  console.log("🚀 ~ OfflineVideoURL:", OfflineVideoURL);
  const [OfflineSubtitleURL, setOfflineSubtitleURL] = useState([]);
  console.log("🚀 ~ OfflineSubtitleURL:", OfflineSubtitleURL);
  const [OfflineAudioURL, setOfflineAudioURL] = useState("");
  console.log("🚀 ~ OfflineAudioURL:", OfflineAudioURL);

  useEffect(() => {
    if (isOffline) {
      initializeDatabase(
        authorization.online._id,
        dbInfo.dbVersion,
        authorization.online._id,
      );
      getSpecificDataFromDB(
        authorization.online._id,
        dbInfo.dbVersion,
        { videoId },
        (videoData) => {
          if (videoData) {
            if (videoData.audioPath instanceof Blob) {
              setOfflineAudioURL(URL.createObjectURL(videoData.audioPath));
            }
            if (videoData.videoPath instanceof Blob) {
              setOfflineVideoURL(URL.createObjectURL(videoData.videoPath));
            }
            if (videoData.subtitlePath instanceof Blob) {
              setOfflineSubtitleURL(
                URL.createObjectURL(videoData.subtitlePath),
              );
            }
          }
        },
        // setOfflineVideoData(videoData);

        authorization.online._id,
      );
    }
  }, [authorization.online._id]);

  const handleShowHideControlsFullScreen = () => {
    if (!isPlaying) {
      setMouseMoving(true);
      return;
    }

    if (isPlaying) {
      setMouseMoving(true);
      const time = 4000;
      let timeout;
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        if (!enableMove) return;
        setMouseMoving(false);
        setEnableMove(false);
      }, time);
    }
  };

  const handleShowHideControls = () => {
    setMouseMoving(true);
  };

  const allSettingList = useMemo(() => {
    return {
      audios: videoData?.audios || [],
      subtitles: [
        {
          language: "off",
          languageName: "off",
          vttPath: "",
          _id: uuidv4(),
        },
        ...(videoData?.subtitles || []),
      ],
      quality: !isOffline
        ? [
            { languageName: t("auto"), _id: uuidv4() },
            { languageName: t("hd"), _id: uuidv4() },
            { languageName: t("low"), _id: uuidv4() },
          ]
        : [
            { languageName: t("auto"), _id: uuidv4() },
            { languageName: t("hd"), _id: uuidv4() },
            // { languageName: t("low"), _id: uuidv4() },
          ],
    };
  }, [
    videoData?.videoId,
    videoData?.audios.length,
    videoData?.audios[0]?.languageName,
    videoData?.subtitles,
  ]);

  const currentSeasonIndex = relatedPartsData.findIndex((season) =>
    season.videos?.find((video) => video.videoId === videoData.videoId),
  );

  const currentVideoIndex = relatedPartsData[currentSeasonIndex]?.seasonName
    ? relatedPartsData[currentSeasonIndex].videos.find(
        (video) => video.videoId === videoData.videoId,
      )?.videoIndex
    : relatedPartsData.find((video) => video.videoId === videoData.videoId)
        ?.videoIndex;

  const isNotFirstVideoInSeries = relatedPartsData[currentSeasonIndex]
    ?.seasonName
    ? !(
        parseInt(currentSeasonIndex) === 0 &&
        parseInt(currentVideoIndex) - 1 === 0
      )
    : parseInt(currentVideoIndex) - 1 !== 0;

  const isNotLastVideoInSeries = relatedPartsData[currentSeasonIndex]
    ?.seasonName
    ? !(
        currentSeasonIndex === relatedPartsData.length - 1 &&
        relatedPartsData[currentSeasonIndex]?.videos?.length ===
          currentVideoIndex
      )
    : relatedPartsData.length !== currentVideoIndex;

  function selectVideo(videoId) {
    let qpData = [videoHeaderId, videoId, isSeries].join("-");
    let qp = {
      qp: qpData,
    };
    setIsEnded(false);
    handleChangeSubtitles(0);
    navigate({
      pathname: ROUTES.WATCH_NOW,
      search: `?${createSearchParams(qp)}`,
    });
  }

  function selectPrevVideo() {
    if (relatedPartsData[currentSeasonIndex]?.seasonName) {
      let currentSeasonIndexVar = currentSeasonIndex;
      let currentVideoIndexVar = currentVideoIndex;
      if (currentVideoIndexVar - 1 === 0 && currentSeasonIndexVar !== 0) {
        currentSeasonIndexVar -= 1;
        // setCurrentSeasonIndex(currentSeasonIndexVar);
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[
            relatedPartsData[currentSeasonIndexVar].videos.length - 1
          ].videoId,
        );
        getCurrentSeasonId(seriesOrParts[currentSeasonIndexVar]._id);
      } else {
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[currentVideoIndex - 2]
            .videoId,
        );
      }
    } else {
      selectVideo(relatedPartsData[currentVideoIndex - 2].videoId);
    }
  }

  function selectNextVideo() {
    let currentSeasonIndexVar = currentSeasonIndex;
    let currentVideoIndexVar = currentVideoIndex;

    if (relatedPartsData[currentSeasonIndexVar]?.seasonName) {
      if (
        relatedPartsData[currentSeasonIndexVar]?.videos?.length ===
          currentVideoIndexVar &&
        currentSeasonIndexVar !== relatedPartsData.length - 1
      ) {
        currentSeasonIndexVar += 1;
        // setCurrentSeasonIndex(currentSeasonIndexVar);
        selectVideo(relatedPartsData[currentSeasonIndexVar].videos[0].videoId);

        getCurrentSeasonId(seriesOrParts[currentSeasonIndexVar]._id);
      } else {
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[currentVideoIndexVar]
            .videoId,
        );
      }
    } else {
      selectVideo(relatedPartsData[currentVideoIndexVar]?.videoId);
    }
  }

  const handleAutoPlayNextVideo = () => {
    if (!isOffline) {
      if (
        relatedPartsData[currentSeasonIndex]?.seasonName
          ? !(
              relatedPartsData[currentSeasonIndex]?.videos?.length ===
                currentVideoIndex &&
              currentSeasonIndex === relatedPartsData.length - 1
            )
          : relatedPartsData.length !== currentVideoIndex
      ) {
        // if (
        //   relatedPartsData[currentSeasonIndex]?.videos[currentVideoIndex]
        //     .isFree ||
        //   relatedPartsData[currentVideoIndex]?.isFree
        //   //  || isSubscribed
        // ) {
        selectNextVideo();
        // }
      } else {
        setIsPlaying(false);
        setIsEnded(true);
      }
    }
  };

  const handleReplayVideo = useCallback(() => {
    setCurrentVideoTime(0);
    setIsEnded(false);
    videoElement.play();
    setIsPlaying(true);
  }, [videoElement]);

  /**HANDLE CONTINUE WATCHING FEATURE */
  const sendTimeForContinueWatching = (videoStatus = "play", time) => {
    const data = {
      LastTime:
        videoStatus === "finished" ? "0" : Math.round(time * 1000).toString(),
      userAccountId: authorization.online._id,
      videoId: videoData.videoId,
    };

    if (videoStatus === "play" && time !== 0) {
      if (!(Math.trunc(time) % 3)) {
        continueWatching(
          data,
          (success) => {},
          (fail) => {},
        );
      }
    }

    if (videoStatus === "finished") {
      continueWatching(
        data,
        (success) => {},
        (fail) => {},
      );
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
    videoElement.play();
  };

  const handlePause = () => {
    setIsPlaying(false);
    videoElement.pause();
  };

  const handlePlayPause = () => {
    if (!isPlaying) handlePlay();
    if (isPlaying) handlePause();
  };

  const handleStartVideo = () => {
    audioElement.play();
  };

  const handleReadyVideo = (e) => {
    setVideoIsReady(true);
  };

  const handleDurationVideo = (value) => {
    setVideoDurationTime(value);
  };

  const handlePlayVideo = () => {
    audioElement.currentTime = videoRef?.current?.getCurrentTime();
    // audioRef.current.seekTo(videoRef?.current?.getCurrentTime(), "seconds");
    audioElement.play();
  };

  const handlePausedVideo = (e) => {
    audioElement.pause();
  };

  const handleSeekVideo = (value) => {
    audioRef.current.seekTo(value, "seconds");
  };

  const handleBufferVideo = () => {
    setVideoIsReady(false);
    audioElement.pause();
  };

  const handleBufferEndVideo = () => {
    if (isPlaying) audioElement.play();
  };

  const handleEndedVideo = useCallback(() => {
    // Setting end and play state to show replay btn
    setIsEnded(true);
    setIsPlaying(false);
    sendTimeForContinueWatching("finished", 0);
    handleAutoPlayNextVideo();
  }, [sendTimeForContinueWatching, handleAutoPlayNextVideo]);

  const handleProgressVideo = useCallback(
    (e) => {
      setCurrentVideoTime(e.playedSeconds);
      setCurrentVideoBufferd(e.loadedSeconds);
      if (!(Math.trunc(e.playedSeconds) % 3) && isPlaying) {
        sendTimeForContinueWatching("play", e.playedSeconds);
      }
    },
    [setCurrentVideoBufferd, isPlaying],
  );

  const handleChangeAudio = useCallback(
    (audioIndex) => {
      if (audioIndex === audio) return;
      videoElement?.pause();
      setIsPlaying(false);
      // setVideoIsReady(false);
      setAudio(audioIndex);
    },
    [setAudio, videoElement, audio],
  );

  // const handleChangeSubtitles = (subtitleIndex) => {
  //   if (videoElement) {
  //     const allTracks = Object.entries(videoElement?.textTracks);
  //     if (!subtitleIndex) {
  //       for (const [key, value] of allTracks) {
  //         value.mode = "disabled";
  //       }
  //     }
  //     if (subtitleIndex) {
  //       for (const [key, value] of allTracks) {
  //         if (+key === subtitleIndex) {
  //           value.mode = "showing";
  //         } else {
  //           value.mode = "disabled";
  //         }
  //       }
  //     }
  //   }
  // };

  const handleChangeSubtitles = useCallback(
    (subtitleIndex) => {
      if (videoElement) {
        const allTracks = Object.entries(videoElement?.textTracks);
        if (!subtitleIndex) {
          for (const [key, value] of allTracks) {
            value.mode = "disabled";
          }
        }
        if (subtitleIndex) {
          for (const [key, value] of allTracks) {
            if (+key === subtitleIndex) {
              value.mode = "showing";
            } else {
              value.mode = "disabled";
            }
          }
        }
      }
    },
    [videoElement],
  );

  const handleChangeSubtitle = useCallback(
    (subtitleIndex = 0) => {
      if (subtitleIndex === subtitle) return;
      setSubtitle(subtitleIndex);
      handleChangeSubtitles(subtitleIndex);
    },
    [subtitle, videoElement],
  );

  const changeQuality = (qualityIndex) => {
    switch (qualityIndex) {
      case 0:
        handleChangeVideoPath(videoData.lowQualityVideoPath);
        break;
      case 1:
        handleChangeVideoPath(videoData.videoPath);
        break;
      case 2:
        handleChangeVideoPath(videoData.lowQualityVideoPath);
        break;
      default:
        break;
    }
  };

  const handleChangeQuality = useCallback(
    (qualityIndex = 0) => {
      // Save the current playback time
      const currentTime = videoRef.current.getCurrentTime();

      // Change the video quality
      setQuality(qualityIndex);
      changeQuality(qualityIndex);

      // Set the playback time back after the new video source is loaded
      videoRef.current.seekTo(currentTime);
    },
    [videoData, quality],
  );

  const handleChangeVideoPath = (path) => {
    if (path === videoPath) return;
    setVideoPath(path);

    videoElement?.pause();
    setVideoIsReady(false);
    setIsPlaying(false);
    audioElement?.pause();
  };

  const handleTimeChange = useCallback((time) => {
    setIsEnded(false);
    videoRef?.current?.seekTo(time / 1000, "seconds");
    audioRef?.current?.seekTo(time / 1000, "seconds");
  }, []);

  const onFullScreenChange = () => {
    if (!document.fullscreenElement) {
      videoFullScreenWrapper?.current?.requestFullscreen();
      return;
    }
    if (document.fullscreenElement) {
      document.exitFullscreen();
      return;
    }
  };

  useEffect(() => {
    setVideoIsReady(false);
    setIsPlaying(false);
  }, [videoData.videoId, videoRef.current]);

  useEffect(() => {
    if (videoData?.subtitles?.length) {
      const subData = allSettingList.subtitles?.map((track, i) => {
        return {
          id: allSettingList.subtitles[i]._id,
          src: addTokenToMedia(
            `${process.env.REACT_APP_BASE_URL}${allSettingList.subtitles[i].vttPath}`,
          ),
          srcLang: allSettingList.subtitles[i].language,
          label: allSettingList.subtitles[i].languageName,
          key: allSettingList.subtitles[i]._id,
          default: !i,
        };
      });

      setAllSubtitles(subData);
    }
  }, [videoData?.subtitles, subtitle, allSettingList, videoData.videoId]);

  useEffect(() => {
    const autoQuality = () => {
      const speed = detectInternetSpeed();
      speed
        .then((res) => {
          const path =
            res <= 0.9 && videoData?.lowQualityVideoPath
              ? videoData.lowQualityVideoPath
              : videoData.videoPath;

          if (path) {
            setVideoPath(path);
            setQuality(0);
            setSubtitle(0);
          }
        })
        .catch((err) => {});
    };
    if (!isOffline) {
      autoQuality();
    }
  }, [videoData.videoId]);

  useEffect(() => {
    if (showFullScreen === "true") {
      onFullScreenChange();
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleChangeFullScreen = () => {
      setIsFullScreen(document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleChangeFullScreen);

    return () =>
      document.addEventListener("fullscreenchange", handleChangeFullScreen);
  }, [setIsFullScreen]);

  useEffect(() => {
    const interval = setInterval(() => {
      setEnableMove(true);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentVideoTime) changeQualitytime.current = currentVideoTime;
    if (videoElement) {
      if (videoElement.src !== videoPath && videoData.videoId === videoId)
        videoElement.currentTime = changeQualitytime.current;
    }
  }, [quality]);

  useEffect(() => {
    if (lastSeenTime && videoElement) {
      setCurrentVideoTime(+videoData.lastSeenTime / 1000);
      videoElement.currentTime = +videoData?.lastSeenTime / 1000;
    }
  }, [lastSeenTime, videoElement]);

  const [onlineVideoUrl, setonlineVideoUrl] = useState(
    !isOffline
      ? addTokenToMedia(`${process.env.REACT_APP_BASE_URL}${videoPath}`)
      : null,
  );
  useEffect(() => {
    const videoUrl = addTokenToMedia(
      `${process.env.REACT_APP_BASE_URL}${videoPath}`,
    );
    setonlineVideoUrl(videoUrl);
  }, [videoPath]);
  console.log("🚀 ~ allSubtitles:", allSubtitles);

  return (
    <div className='watch-now__video-show__wrapper'>
      <div
        className='watch-now__video-show'
        ref={videoFullScreenWrapper}
        onMouseMoveCapture={
          isFullScreen
            ? handleShowHideControlsFullScreen
            : handleShowHideControls
        }
        onMouseOut={() => setMouseMoving(false)}
      >
        {
          <ReactPlayer
            ref={videoRef}
            key={videoData._id}
            // key={videoPath}
            width='100%'
            height='100%'
            muted
            // playing
            onPause={handlePausedVideo}
            onStart={handleStartVideo}
            onBuffer={handleBufferVideo}
            onBufferEnd={handleBufferEndVideo}
            onDuration={handleDurationVideo}
            onProgress={handleProgressVideo}
            onPlay={handlePlayVideo}
            onReady={handleReadyVideo}
            onSeek={handleSeekVideo}
            onEnded={handleEndedVideo}
            url={
              isOffline ? OfflineVideoURL : videoPath ? onlineVideoUrl : null
            }
            config={{
              file: {
                attributes: {
                  crossOrigin: "anonymous",
                  poster: videoData?.imgPath
                    ? addTokenToMedia(
                        `${process.env.REACT_APP_BASE_URL}${videoData?.imgPath}`,
                      )
                    : null,
                },
                tracks: !isOffline
                  ? allSubtitles
                  : [
                      {
                        id: "fe181b57-34fe-48bb-833a-c8c08cd3271d",
                        src: "http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:5001/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDM1NzQ3MzNiMjJjN2M5NmFhZjI5MGUiLCJpYXQiOjE3MTE4OTMyNDksImV4cCI6MTcxMjQ5ODA0OX0.POBX9bw6RICjfgzaFlDOrHteR7V-Kt4FHDoTCHPpumI",
                        srcLang: "off",
                        label: "off",
                        key: "fe181b57-34fe-48bb-833a-c8c08cd3271d",
                        default: true,
                      },
                      {
                        id: "65a67e1f1e0e906b592b023f",
                        src: OfflineSubtitleURL,
                        srcLang: "",
                        label: "",
                        key: "65a67e1f1e0e906b592b023f",
                        default: false,
                      },
                    ],

                forceVideo: true,
              },
            }}
          />
        }
        {!isFree && !isPaid ? (
          <>
            <div class='overlay' key={isFree}>
              <div class='overlay-content'>
                {/* <Button className='button-primary button-primary--joinCompetition'>
                    {t("subscribeNowWithout")}
                  </Button> */}
              </div>
            </div>
            <SubscribenowModal
              open={showSubscribeNowModal}
              onCancel={() => setshowSubscribeNowModal(false)}
            />
          </>
        ) : !videoIsReady ? (
          <LoadingSpinner />
        ) : (
          <div
            className={`video-controller__wrapper video-controller ${
              mouseMoving ? "show" : "hide"
            }`}
          >
            <Controls
              videoRef={videoRef}
              audioRef={audioRef}
              videoData={videoData}
              videoTitle={videoData.title}
              isEnded={isEnded}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              isNotFirstVideoInSeries={isNotFirstVideoInSeries}
              isNotLastVideoInSeries={isNotLastVideoInSeries}
              isFullScreen={isFullScreen}
              handlePlayPause={handlePlayPause}
              allSettingList={allSettingList}
              setAudio={handleChangeAudio}
              setSubtitle={handleChangeSubtitle}
              setQuality={handleChangeQuality}
              audio={audio}
              subtitle={subtitle}
              quality={quality}
              currentVideoTime={currentVideoTime}
              currentVideoBufferd={currentVideoBufferd}
              videoDurationTime={videoDurationTime}
              handleTimeChange={handleTimeChange}
              selectPrevVideo={selectPrevVideo}
              selectNextVideo={selectNextVideo}
              handleReplayVideo={handleReplayVideo}
              onFullScreenChange={onFullScreenChange}
              videoFullScreenWrapper={videoFullScreenWrapper}
            />
          </div>
        )}
      </div>

      <ReactPlayer
        ref={audioRef}
        // playing
        // className="d-none"
        // key={OfflineAudioURL}
        // controls
        height='0'
        url={
          isOffline
            ? OfflineAudioURL
            : videoData?.audios[audio]?.path
            ? addTokenToMedia(
                `${process.env.REACT_APP_BASE_URL}${videoData?.audios[audio]?.path}`,
              )
            : null
        }
        // onReady={handleReadyAudio}
        config={{
          file: {
            forceAudio: true,
          },
        }}
      />
    </div>
  );
};

export default VideoShowTest;

import React from "react";
import SettingItem from "./SettingItem";
import audioIcon from "assets/images/audio.svg";
import ccIcon from "assets/video-controllers/cc.svg";
import QualityIcon from "assets/video-controllers/setting.svg";
import soundUpIcon from "assets/video-controllers/volume-up.svg";
import Volume from "./Volume";
import { v4 as uuidv4 } from "uuid";
import { t } from "i18next";
const SettingList = ({
  videoRef,
  audioRef,
  allSettingList,
  setAudio,
  setSubtitle,
  setQuality,
  audio,
  subtitle,
  quality,
  videoFullScreenWrapper,
}) => {
  const allSetting = [
    {
      id: uuidv4(),
      title: t("audios"),
      icon: audioIcon,
      options: allSettingList.audios,
      setValue: setAudio,
      activeOption: audio,
    },
    {
      id: uuidv4(),
      title: t("subtitle"),
      icon: ccIcon,
      options: allSettingList.subtitles,
      setValue: setSubtitle,
      activeOption: subtitle,
    },
    {
      id: uuidv4(),
      title: t("quality"),
      icon: QualityIcon,
      options: allSettingList.quality,
      setValue: setQuality,
      activeOption: quality,
    },
  ];
  return (
    <>
      {allSetting?.map((item, index) => (
        <SettingItem
          item={item}
          key={item.id}
          videoFullScreenWrapper={videoFullScreenWrapper}
        />
      ))}
      <Volume
        audioRef={audioRef}
        videoFullScreenWrapper={videoFullScreenWrapper}
      />
    </>
  );
};

export default React.memo(SettingList);

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LANG_PARAMS } from "localization/translation-params";
import { Image } from "react-image-and-background-image-fade";
import { addDeleteWishList } from "modules/Shared/netwok";
import checkedWishListButton from "assets/icons/checked-wish-list.svg";
import addWishlistButton from "assets/icons/plus-button.svg";
import Skeleton from "react-loading-skeleton";
import AudioIcon from "assets/icons/audio.png";
import ccIcon from "assets/icons/cc.png";
import VideoCast from "./VideoCast";
import { toastError } from "helpers/toasters";
import { Select } from "antd";
import downloadRegular from "assets/icons/download_regular.svg";
import downloadHover from "assets/icons/download_hover.svg";
import downloadedRegular from "assets/icons/downloaded_regular.svg";
import downloadedHover from "assets/icons/downloaded_hover.svg";
import DownloadModal from "./DownloadModal";
import { useLocation, useSearchParams } from "react-router-dom";
import useSyncDeleteDownloads from "modules/MyDownloads/useSyncDeleteDownloads";
import useDeleteVideo from "modules/MyDownloads/useDeleteVideo";
import { deleteVideoFromDownloads } from "../network";

const VideoDetials = ({
  videoDetails,
  isLoading,
  seriesOrParts,
  getCurrentSeasonId,
  isSeries,
  getVideoDetailss,
  castsList,
  isFree,
}) => {
  const [isWished, setIsWished] = useState(false);
  const [addWishListLoading, setAddWishListLoading] = useState(false);
  const [seasons, updateSeasons] = useState([]);
  const [isDownloadHov, updateIsDownloadHov] = useState(false);
  const [isDownloadModalVisible, toggleDownloadModal] = useState(false);
  const [currentSeasonId, updateCurrentSeasonId] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();

  // const [isOnline, setIsOnline] = useState(false);
  const { authorization } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const urlparams = new URLSearchParams(location.search);

  // const isOffline = isOfflineParam === "true";
  const isOffline = !urlparams.get("isOffline")
    ? false
    : urlparams.get("isOffline");

  const [videoHeaderId, videoId] = params.qp.split("-");
  const [hovered, setHovered] = useState(false);
  const isSubscribed = authorization?.isPaid?.isPaid;

  const {
    isDownloaded,
    updateDownloadedState, // Return the update function
  } = useSyncDeleteDownloads(authorization, videoId);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    updateCurrentSeasonId(videoDetails?.seasonId);
  }, [videoDetails?.seasonId]);

  useEffect(() => {
    let seasonsArr = [];
    seriesOrParts.forEach((part) => {
      seasonsArr.push({ value: part._id, label: part.seasonName });
    });
    updateSeasons(seasonsArr);
    if (seasonsArr.length) {
      const seasonId = seriesOrParts?.find((season) =>
        season.videos?.find((video) => video.videoId === videoId),
      );
      getCurrentSeasonId(seasonId?._id);
    }

    // eslint-disable-next-line
  }, [seriesOrParts]);
  useEffect(() => {
    if (videoDetails) {
      setIsWished(videoDetails.isWished);
    }
  }, [videoDetails, setIsWished]);

  function addToWishList() {
    setAddWishListLoading(true);
    const payload = {
      accountId: authorization.online.id,
      videoId: videoDetails.videoHeaderId,
      isDeleted: isWished,
    };
    addDeleteWishList(
      payload,
      (success) => {
        if (success.data) {
          setIsWished(!isWished);
          setAddWishListLoading(false);
        }
        if (success.data.message) toastError(success.data.message);
      },
      (fail) => {
        if (fail?.data.err_msg) toastError(fail.data.err_msg);
        else toastError();
      },
    );
  }
  const handleChangeSeason = (seasonId) => {
    getCurrentSeasonId(seasonId);
    updateCurrentSeasonId(seasonId);
  };
  const { deleteVideo, isDeleting } = useDeleteVideo(
    authorization,
    videoDetails.videoId,
  );

  const handleDeleteVideo = (e) => {
    if (!isDownloaded && videoDetails.isDownloaded) {
      const payload = {
        userAccountId: authorization.online._id,
        videoId: videoId,
        isDeleted: true,
      };
      deleteVideoFromDownloads(
        payload,
        () => {
          getVideoDetailss();
        },
        () => {},
      );
    } else {
      updateDownloadedState();
      deleteVideo();
    }
  };
  const download = () => {
    if (!videoDetails?.isDownloaded) {
      toggleDownloadModal(true);
    }
  };
  const onDownloadMouseEnter = () => {
    updateIsDownloadHov(true);
  };
  const onDownloadMouseLeave = () => {
    updateIsDownloadHov(false);
  };
  return (
    <div className='video-details'>
      <div className='video-details__head'>
        <header className='video-details__header'>
          <h4>{isLoading ? <Skeleton width={250} /> : videoDetails.title}</h4>
          {isSeries ? (
            isLoading ? (
              <div className='video-details__season'>
                <Skeleton width={85} />
              </div>
            ) : (
              <div className='video-details__season'>
                <Select
                  defaultValue={currentSeasonId}
                  value={currentSeasonId}
                  style={{
                    width: 120,
                  }}
                  onChange={handleChangeSeason}
                  options={seasons}
                />
              </div>
            )
          ) : (
            <></>
          )}
        </header>
        {true && (
          <div className='d-flex'>
            <div
              className={`video-details__my-list mx-2 ${
                addWishListLoading ? "disable-pointer" : "cursor-pointer"
              }`}
              onClick={addToWishList}
            >
              {isLoading ? (
                <Skeleton width={86} />
              ) : (
                <>
                  <>
                    {isWished ? (
                      <img
                        src={checkedWishListButton}
                        alt='added-to-wish-list'
                      />
                    ) : (
                      <img src={addWishlistButton} alt='add-to-wish-list' />
                    )}
                    <div className='fw-600'>{t(LANG_PARAMS.myList)}</div>
                  </>
                </>
              )}
            </div>
            {isSubscribed && (
              <div
                className='video-details__my-list cursor-pointer mx-2'
                onClick={
                  videoDetails?.isDownloaded ? handleDeleteVideo : download
                }
              >
                {isLoading ? (
                  <Skeleton width={86} />
                ) : (
                  <>
                    <>
                      <img
                        onMouseEnter={onDownloadMouseEnter}
                        onMouseLeave={onDownloadMouseLeave}
                        src={
                          isDownloadHov
                            ? videoDetails?.isDownloaded
                              ? downloadedHover
                              : downloadHover
                            : videoDetails?.isDownloaded
                            ? downloadedRegular
                            : downloadRegular
                        }
                        alt='download'
                      />
                      <div className='mx-2 f-18 fw-600'>
                        {videoDetails?.isDownloaded
                          ? `${t("downloaded")}`
                          : `${t("download")}`}
                      </div>
                    </>
                  </>
                )}
              </div>
            )}
            {/* {isSubscribed && isDownloaded && (
              <div>
                {deletingState ? (
                  <Spin
                    size='small'
                    style={{
                      width: "26px",
                      height: "26px",
                      fontSize: "24px",
                      color: "#fd4885",
                    }}
                  />
                ) : (
                  <DeleteOutlined
                    style={{
                      fontSize: hovered ? "26px" : "24px",
                      transition:
                        "transform 0.1s ease-in-out, font-size 0.1s ease-in-out",
                      transform: hovered ? "scale(1.03)" : "scale(1)",
                      lineHeight: "0",
                      height: "26px",
                      width: "26px",
                      alignSelf: "center",
                      WebkitBackgroundClip: "text",
                      backgroundClip: "text",
                      color: "#fd4885",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleDeleteVideo}
                  />
                )}
              </div>
            )} */}
          </div>
        )}
      </div>

      <ul className='video-details__categories'>
        <li className='item'>
          {isLoading ? <Skeleton width={100} /> : videoDetails.category}
        </li>
        <li className='item'>
          {isLoading ? <Skeleton width={100} /> : videoDetails.genre}
        </li>
      </ul>
      <div className='video-details__description'>
        <p>
          {isLoading ? (
            <>
              <Skeleton count={3} width={"100%"} />
              <Skeleton width={"45%"} />
            </>
          ) : (
            videoDetails.description
          )}
        </p>
      </div>
      <div className='video-details__langs'>
        <h5>{t("availableLang")}:</h5>
        <ul className='lang__wrapper'>
          {videoDetails?.subtitles && (
            <li>
              <Image
                wrapperClassName={"icon-wrapper"}
                src={ccIcon}
                alt='avilable-subtitles'
              />
              <div className='lang-text'>
                <strong>{t(LANG_PARAMS.subtitles)} :</strong>
                {isLoading
                  ? new Array(2).fill(null).map((item) => (
                      <span key={item}>
                        <Skeleton width={100} inline />
                      </span>
                    ))
                  : videoDetails?.subtitles?.map((subtitle) => (
                      <span key={subtitle}>{subtitle.languageName}</span>
                    ))}
              </div>
            </li>
          )}
          <li>
            <Image
              wrapperClassName={"icon-wrapper"}
              src={AudioIcon}
              alt='avilable-audios'
            />
            <div className='lang-text'>
              <strong>{t(LANG_PARAMS.audios)} :</strong>
              {isLoading
                ? new Array(2).fill(null)?.map((item, _) => (
                    <span key={item}>
                      <Skeleton width={100} inline />
                    </span>
                  ))
                : videoDetails.audios?.map((audio) => (
                    <span key={audio}>{audio.languageName}</span>
                  ))}
            </div>
          </li>
        </ul>
      </div>
      <VideoCast isLoading={isLoading} castData={castsList} />
      <DownloadModal
        isModalVisible={isDownloadModalVisible}
        onCancel={() => {
          toggleDownloadModal(false);
          updateDownloadedState();
          getVideoDetailss();
        }}
        videoDetails={videoDetails}
      />
    </div>
  );
};

export default VideoDetials;

import { postResource, putResource } from "../../services";

export function getSearchResults(data, onSuccess, onFail) {
	const { lang } = data;
	const path = `api/search?lang=${lang}`;
	postResource(path, data, onSuccess, onFail);
}

export function changeLanguage(data, onSuccess, onFail) {
	const { lang } = data;
	const path = `api/userAccounts/language?lang=${lang}`;
	putResource(path, data, onSuccess, onFail);
}

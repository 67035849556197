import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button } from "antd";
import { Container } from "react-bootstrap";
import passwordIcon from "assets/icons/password.svg";
import { signin } from "./network";
import { toastError } from "helpers/toasters";
import { login } from "store/authorization";
import { v4 as uuidv4 } from "uuid";
import ROUTES from "constants/_routes";
import WebsiteTitle from "helpers/WebsiteTitle";
import { browserName } from "react-device-detect";
import InputPhone from "./components/InputPhone";
import InputField from "./components/InputField";
import { useRef } from "react";
import { useEffect } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

function SignIn() {
  const [phoneValues, setPhoneValues] = useState({
    code: "20",
    phone: "",
    short: "EG",
  });
  async function generateBrowserFingerprint() {
    const fp = await FingerprintJS.load();
    const result = await fp.get();

    return result.visitorId;
  }
  const { lang, dir } = useSelector((state) => state.lang);
  const [isSubmitted, updateIsSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // const [showError, setShowError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formRef = useRef(null);

  // useEffect(() => {
  //   if (showError) {
  //     formRef.current.validateFields();
  //   }
  // }, [i18n.language, showError]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function onSubmit(e) {
    updateIsSubmitted(false);
    // setShowError(false);

    var ph;
    if (!phoneError && password.length) {
      setLoading(true);
      const codeIsEndWithZero = phoneValues?.code.toString().endsWith("0");
      const phoneIsEndWithZero = phoneValues.phone.startsWith("0");
      if (codeIsEndWithZero && phoneIsEndWithZero) {
        ph = phoneValues.phone.slice(1);
      } else {
        ph = phoneValues.phone;
      }
      const FBToken = localStorage.getItem("FBToken");
      let data = {
        phone: phoneValues.code + ph,
        password,
        firebaseToken: FBToken ? FBToken : "there's no firebase token",
      };

      signin(
        data,
        (success) => {
          setLoading(false);
          generateBrowserFingerprint().then((fingerprint) => {
            let deviceId = fingerprint;
            let SigninData = { ...success.data, deviceId, browserName };
            dispatch(login(SigninData));
            setTimeout(() => {
              navigate(ROUTES.WATCHERS, { replace: true });
            }, 0);
          });
        },
        (fail) => {
          setLoading(false);
          if (fail.data?.err_msg) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        },
        { "Accept-Language": lang },
      );
    }
  }

  const getErrorMessage = (value) => {
    setPhoneError(value);
  };

  const getPhoneValues = (values) => {
    setPhoneValues(values);
  };

  return (
    <section className='auth main-section '>
      <WebsiteTitle title={"Sign In"} />
      <Container>
        <div className='auth__title text-center'>{t("login")}</div>
        <Row>
          <Col xs={24}>
            <Form onFinish={onSubmit} className='auth__form auth__form--w50'>
              <div className='auth__input-phone '>
                <InputPhone
                  itemClassName={"auth__form-item"}
                  getErrorMessage={getErrorMessage}
                  getPhoneValues={getPhoneValues}
                  phoneValues={phoneValues}
                  mode='initial'
                  isSubmitted={isSubmitted}
                  key={i18n.language}
                />
              </div>
              <InputField
                itemClassName={"auth__form-item"}
                name='password'
                initialVal={password}
                handleOnChange={setPassword}
                rules={[
                  { required: true, message: t("providePassword") },
                  {
                    min: 6,
                    message: t("passwordLength"),
                  },
                ]}
                prefix={<img src={passwordIcon} alt='password-icon' />}
                placeholder={t("password")}
              />
              <div className='auth__forget-text'>
                <Link className='' to={ROUTES.FORGET_PASSWORD}>
                  {t("forgetPassword")}
                </Link>
              </div>
              <div className='text-center'>
                <Button
                  loading={loading}
                  className='auth__btn button-primary'
                  htmlType='submit'
                  onClick={() => updateIsSubmitted(true)}
                >
                  {t("login")}
                </Button>
                <div className='auth__sub-text '>
                  <span>{t("dontHaveAccount")}</span>
                  <Link to={ROUTES.SIGNUP}>{t("signupNow")}</Link>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default SignIn;

import React from "react";
import { Modal } from "antd";
import Lottie from "react-lottie";
import success from "../../../assets/animated-images/gift_answer-success.json";
import fail from "../../../assets/animated-images/gift_answer_fail.json";
import answered from "../../../assets/animated-images/gift_answered.json";
import closeIcon from "../../../assets/icons/close.svg";
function ResponseModal({ isModalVisible, onCancel, status }) {
  const responseData = [
    {
      status: "success",
      title: "إجابتك صحيحة",
      // message: "لقد دخلت السحب (معلومة توضيحية للسحب لسة مش عارفينها :|)",
      message: "",
      icon: success,
      styles: {
        title: {
          color: "#356AB3",
          fontSize: "25px",
        },
        message: {
          color: "#707070",
          fontSize: "21px",
          width: "40%",
          margin: "auto",
        },
      },
    },
    {
      status: "fail",
      title: "إجابتك خاطئة",
      message: "حظ أوفر في السؤال القادم، استمر في المحاولة",
      icon: fail,
      styles: {
        title: {
          color: "#ED2024",
          fontSize: "25px",
        },
        message: {
          color: "#707070",
          fontSize: "21px",
          width: "40%",
          margin: "auto",
        },
      },
    },
    {
      status: "answered",
      title: "",
      message: "لقد أجبت على سؤال اليوم، انتظر سؤال جديد في وقت لاحق",
      icon: answered,
      styles: {
        message: {
          color: "#356AB3",
          fontSize: "25px",
          width: "40%",
          margin: "auto",
        },
      },
    },
    {
      status: "noQuestionAndNoPreviousAnswer",
      title: "",
      message: "انتظر سؤال جديد في وقت لاحق",
      icon: answered,
      styles: {
        message: {
          color: "#356AB3",
          fontSize: "25px",
          width: "40%",
          margin: "auto",
        },
      },
    },
  ];

  const selectedResponseData = responseData.filter(
    (data) => data.status === status
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: selectedResponseData.length
      ? selectedResponseData[0].icon
      : "",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Modal
      centered
      open={isModalVisible}
      width={620}
      onCancel={onCancel}
      className="signin-modal modal-container text-center"
      footer={false}
      closeIcon={<img src={closeIcon} alt="icon" style={{ width: "30px" }} />}
    >
      <h5 className="my-2" style={selectedResponseData[0]?.styles?.title}>
        {selectedResponseData[0]?.title}
      </h5>
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        height={127}
        width={127}
      />
      {/* <div className="my-2" style={selectedResponseData[0]?.styles?.message}>
        {selectedResponseData[0]?.message}
      </div> */}
      {selectedResponseData[0]?.message &&
        <div className="my-2" style={selectedResponseData[0]?.styles?.message}>
          {selectedResponseData[0]?.message}
        </div>}
    </Modal>
  );
}

export default ResponseModal;

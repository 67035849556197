import { useRef, useState, useEffect } from "react";
import { Button } from "antd";
import { /*Link,*/ useNavigate, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
// import amuseLogo from "assets/images/amuse-logo.svg";
import { useTranslation } from "react-i18next";
import { confirmCode, forgetPassword } from "../network";
import Countdown from "antd/lib/statistic/Countdown";
import { toastError } from "helpers/toasters";
import ROUTES from "constants/_routes";
import Spinner from "modules/Shared/Spinner";
import { OTPInputComponent } from "../components/OTPInput/Index";

const OtpCodeVerification = () => {
  const { t } = useTranslation();
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  // const time = ;
  const [timer, setTimer] = useState(Date.now() + 1000 * 5 * 12);

  useEffect(() => {
    if (location?.state?.phone) {
      setShowTimer(true);
    } else {
      navigate(ROUTES.FORGET_PASSWORD);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (code?.length === 5) setShowErrMsg(false);
  }, [code?.length]);

  function onSubmit() {
    if (code.length === 5) {
      setLoading(true);
      let data = {
        code,
        phone: location.state.phone,
      };
      confirmCode(
        data,
        (success) => {
          setLoading(false);
          setCode("");
          if (success.isSuccess && success.data.isConfirmed) {
            navigate(
              location?.state?.from === "verifyPinCode"
                ? ROUTES.CREATE_PARENTAL_CODE
                : ROUTES.RESET_PASSWORD,
              {
                replace: true,
                state: {
                  phone: location.state.phone,
                  code,
                },
              },
            );
          }

          if (!success.isSuccess) toastError(success.err_msg);
        },
        (fail) => {
          setLoading(false);
          setCode("");
          // setShowErrMsg(true);
          toastError(t("otpCodeError"));

          if (fail.data.err_msg) {
          } else {
            toastError();
          }
        },
      );
    } else if (code.length === 0) {
      toastError(t("emptyOtpCode"));
    } else {
      // setShowErrMsg(true);
      toastError(t("otpCodeError"));
    }
  }

  function onfinishTimer() {
    setShowTimer(false);
  }

  function resendCode() {
    setIsSpinnerLoading(true);
    setCode("");
    forgetPassword(
      { phone: location.state.phone },
      (success) => {
        if (success?.isSuccess) {
          setShowTimer(true);
          setTimer(Date.now() + 1000 * 5 * 12);
          setIsSpinnerLoading(false);
        } else {
          toastError(success.err_msg);
          setIsSpinnerLoading(false);
        }
      },
      (fail) => {
        if (fail.data.err_msg) {
          toastError(fail.data.err_msg);
          setIsSpinnerLoading(false);
        } else {
          toastError();
          setIsSpinnerLoading(false);
        }
      },
    );
  }

  return (
    <>
      {isSpinnerLoading && <Spinner />}
      <section className='auth main-section '>
        <Container>
          <div className='logo-center mt-3'>
            {/* <Link to="/">
						<img src={amuseLogo} alt="amuse the kids" />
					</Link> */}
          </div>
          <div className='auth__blue-title text-blue-header text-center'>
            {t("verifyOtpCode")}
          </div>
          <div className='auth__orange-title text-orange-header text-center'>
            {t("enterOtpNumber")}
          </div>
          <div className='box my-2'>
            {" "}
            <Countdown
              className='text-center auth__timer'
              value={timer}
              format='mm:ss'
              onFinish={onfinishTimer}
            />
          </div>

          <div className='otp__container  text-center mt-2'>
            <div className='d-flex justify-content-center' dir='ltr'>
              <OTPInputComponent
                length={5}
                isNumberInput
                autoFocus
                className='otpContainer'
                inputClassName='otpInput'
                onChangeOTP={setCode}
                isSecure
                onSubmit={onSubmit}
              />
            </div>
            {/* <div
            className="error-message"
            style={{
              opacity: showErrMsg ? 1 : 0,
            }}
          >
            {t("otpCodeError")}
          </div> */}
          </div>
          <div className='text-center'>
            <Button
              loading={loading}
              className='button-primary '
              htmlType='submit'
              onClick={onSubmit}
            >
              {t("confirmCode")}
            </Button>
            {/* <div className="auth__sub-text mb-4">
            <span>{t("didntGetCode")}</span>
            <button
              className="blue-text btn text-btn"
              disabled={showTimer}
              onClick={resendCode}
            >
              {t("resendCode")}
            </button>
          </div> */}
            <div className='auth__sub-text mb-4'>
              <span>{t("didntGetCode")}</span>
              <span
                role={showTimer ? "" : "button"}
                disabled={showTimer}
                onClick={showTimer ? null : resendCode}
                className={`auth__forget-pin-code-resend-code ${
                  showTimer ? "resend-code-inActive" : ""
                }`}
              >
                {t("resendOtpCode")}
              </span>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OtpCodeVerification;

import React from "react";
import VideoTitle from "./VideoTitle";
import CenterControls from "./CenterControls";
import BottomController from "./BottomController";

const Controls = ({
  videoRef,
  audioRef,
  videoData,
  videoTitle,
  showLists,
  isEnded,
  isPlaying,
  setIsPlaying,
  isFullScreen,
  handlePlayPause,
  handleShowHideLists,
  isNotFirstVideoInSeries,
  isNotLastVideoInSeries,
  selectNextVideo,
  onTrackChange,
  allSettingList,
  setAudio,
  setSubtitle,
  setQuality,
  currentVideoTime,
  currentVideoBufferd,
  videoDurationTime,
  handleTimeChange,
  audio,
  subtitle,
  quality,
  selectPrevVideo,
  handleReplayVideo,
  onFullScreenChange,
  videoFullScreenWrapper,
}) => {
  return (
    <>
      <VideoTitle videoTitle={videoTitle} />
      <CenterControls
        videoRef={videoRef}
        isEnded={isEnded}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        handlePlayPause={handlePlayPause}
        handleReplayVideo={handleReplayVideo}
      />
      <BottomController
        isNotFirstVideoInSeries={isNotFirstVideoInSeries}
        isNotLastVideoInSeries={isNotLastVideoInSeries}
        isFullScreen={isFullScreen}
        selectNextVideo={selectNextVideo}
        handleShowHideLists={handleShowHideLists}
        videoData={videoData}
        showLists={showLists}
        onTrackChange={onTrackChange}
        allSettingList={allSettingList}
        videoRef={videoRef}
        audioRef={audioRef}
        setAudio={setAudio}
        setSubtitle={setSubtitle}
        setQuality={setQuality}
        audio={audio}
        subtitle={subtitle}
        quality={quality}
        currentVideoTime={currentVideoTime}
        currentVideoBufferd={currentVideoBufferd}
        videoDurationTime={videoDurationTime}
        handleTimeChange={handleTimeChange}
        selectPrevVideo={selectPrevVideo}
        onFullScreenChange={onFullScreenChange}
        videoFullScreenWrapper={videoFullScreenWrapper}
      />
    </>
  );
};

export default Controls;

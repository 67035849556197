import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Form, Button } from "antd";
import { toastError } from "helpers/toasters";
import { createParentalCode, enterUserAccount, getUserData } from "../network";
import amuseLogo from "assets/images/amuse-logo.svg";
import { changeAuthData, login, onlineUser } from "store/authorization";
import ROUTES from "constants/_routes";
import { OTPInputComponent } from "../components/OTPInput/Index";

function CreateParentalCode() {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authorization } = useSelector((state) => state.auth);
  const { mode, userData, from } = location?.state;

  useEffect(() => {
    if (isSubmitted && code.length !== 4) {
      setError(t("enterValidCode"));
    } else {
      setError("");
    }
  }, [code.length, isSubmitted, t]);

  const handleCreateCode = (data) => {
    createParentalCode(
      data,
      (success) => {
        setLoading(false);
        dispatch(onlineUser(userData));
        dispatch(changeAuthData({ pinCode: code }));

        if (mode === "add") {
          const enterUserAccountPayload = {
            accountId: userData._id,
            deviceId: authorization.deviceId,
            deviceName: authorization.browserName,
            userToken: authorization.token,
          };

          enterUserAccount(
            enterUserAccountPayload,
            (success) => {
              dispatch(changeAuthData({ isEnteredUserAccount: true }));
              setTimeout(() => {
                navigate(ROUTES.ADD_CHILDS, {
                  state: { mode: "add", from },
                });
              }, 0);
            },
            (fail) => {
              toastError(fail?.data?.err_msg);
            },
          );
        }
        if (mode === "enter") {
          navigate(ROUTES.HOME, {
            state: {
              selectedUser: true,
              mode: "enter",
              online: userData,
            },
          });
        }
        if (mode === "forgetPinCode") {
          navigate(ROUTES.WATCHERS, { state: { mode } });
        }
      },
      (fail) => {
        setLoading(false);
        if (fail.data) toastError(fail.data.err_msg);
        else toastError(t("anErrorOccured"));
      },
    );
  };

  function onSubmit() {
    setIsSubmitted(true);

    if (code?.length === 4) {
      setError("");
      setLoading(true);
      const createParentalCodePayload = {
        code,
      };
      getUserData(
        { userId: authorization._id },
        (res) => {
          let parentId = res.data.userAccounts.filter(
            (el) => el.isParent === true,
          )[0]?.id;
          createParentalCodePayload.parentId = parentId;
          dispatch(changeAuthData({ userAccounts: res.data.userAccounts }));
          handleCreateCode(createParentalCodePayload);
        },
        (fail) => {
          setLoading(false);
          if (fail.data) toastError(fail.data.err_msg);
          else toastError(t("anErrorOccured"));
        },
      );
    } else {
      setError(t("enterValidCode"));
    }
  }
  return (
    <section className='main-section PartenalCode'>
      <Container>
        <div className='text-center my-5'>
          <Link to={ROUTES.HOME}>
            <img src={amuseLogo} alt='amuse the kids' />
          </Link>
        </div>
        <div className='single-centered-title text-center'>{t("pinCode")}</div>

        <div
          className='single-centered-subtitle text-center'
          style={{ fontWeight: "400" }}
        >
          {t("createParentalCode")}
        </div>

        <div className='otp__container  text-center'>
          <div className='d-flex justify-content-center ' dir='ltr'>
            <OTPInputComponent
              length={4}
              isNumberInput
              autoFocus
              className='otpContainer'
              inputClassName='otpInput'
              hasErrored={error}
              errorStyle={{
                border: "1px solid red",
              }}
              onChangeOTP={setCode}
            />
            {/* <OtpInput
              className="otp__single-container mb-1"
              value={code}
              onChange={setCode}
              isInputNum
              numInputs={4}
              shouldAutoFocus
              hasErrored={error}
              errorStyle={{
                border: "1px solid red",
              }}
            /> */}
          </div>
        </div>
        <div
          className='otp__errorMessage'
          style={{
            visibility: error ? "visible" : "hidden",
            transition: "all 0.5s ease-in-out",
            opacity: error ? 1 : 0,
          }}
        >
          {t("enterValidCode")}
        </div>
        <div className='pay__button-primary--cent'>
          <Button
            className='button-primary parentalCode-btn'
            loading={loading}
            onClick={onSubmit}
          >
            {t("save")}
          </Button>
        </div>
      </Container>
    </section>
  );
}

export default CreateParentalCode;

import React, { useRef, useState } from "react";
import { VideoSeekSlider } from "react-video-seek-slider";
import timeFormatter from "format-duration";
const VideoProgressbar = ({
  currentVideoTime,
  currentVideoBufferd,
  videoDurationTime,
  handleTimeChange,
  videoRef,
}) => {
  return (
    <>
      <div className={`video-show-progress`}>
        <VideoSeekSlider
          max={videoDurationTime * 1000}
          secondsPrefix='00:'
          minutesPrefix='0:'
          currentTime={currentVideoTime * 1000}
          bufferTime={currentVideoBufferd * 1000}
          onChange={(time) => handleTimeChange(time)}
        />
      </div>
      <div className='timer'>
        <p>{timeFormatter(currentVideoTime * 1000 || 0)}</p>
        <p>{timeFormatter(videoDurationTime * 1000 || 0)}</p>
      </div>
    </>
  );
};

export default VideoProgressbar;

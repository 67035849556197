import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
//import { continueWatching } from "modules/WatchNow/network";
import NoInternetPage from "./NoInternetPage";
import MyDownloads from "modules/MyDownloads/MyDownloads";
import WatchNow from "modules/WatchNow/WatchNow";
import { useSelector } from "react-redux";
import Footer from "modules/components/Footer";
import NewNavbar from "modules/components/NewNavbar";
import ROUTES from "constants/_routes";
import PageNotFound from "modules/PageNotFound/PageNotFound";
function NoInternet(props) {
  const location = useLocation();
  const { authorization } = useSelector((state) => state.auth);
  const isPaid = authorization?.isPaid?.isPaid;
  const navigate = useNavigate();

  const redirectToHome =
    location.pathname === ROUTES.NOINTERNET ||
    location.pathname === ROUTES.NOT_FOUND;

  const redirectToNoInternet = !(location.pathname === ROUTES.WATCH_NOW);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      if (navigator.onLine && redirectToHome) {
        navigate(ROUTES.HOME, { replace: true });
      }
      // Prevent redirect to the NoInternet page during watching
      if (!navigator.onLine && redirectToNoInternet) {
        navigate(ROUTES.NOINTERNET, { replace: true });
      }
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [navigate]);

  const hasNav = [
    ROUTES.MY_DOWNLOADS,
    ROUTES.WATCH_NOW,
    ROUTES.NOINTERNET,
    ROUTES.NOT_FOUND,
    ROUTES.HOME,
  ];
  const hasFooter = [
    ROUTES.MY_DOWNLOADS,
    ROUTES.WATCH_NOW,
    ROUTES.NOINTERNET,
    ROUTES.NOT_FOUND,
    ROUTES.HOME,
  ];

  return (
    <>
      {navigator.onLine ? (
        <div>{props.children}</div>
      ) : (
        <div>
          {hasNav.includes(location.pathname) && <NewNavbar />}

          <Routes>
            <Route exact path={ROUTES.MY_DOWNLOADS} element={<MyDownloads />} />
            <Route
              exact
              path={ROUTES.HOME}
              element={<Navigate to={ROUTES.MY_DOWNLOADS} />}
            />
            {/* <Route
              path={ROUTES.MY_LIST}
              element={<Navigate to={ROUTES.MY_DOWNLOADS} />}
            /> */}
            <Route
              path={ROUTES.WATCH_NOW}
              element={
                authorization?.token ? (
                  <WatchNow />
                ) : (
                  <Navigate to={ROUTES.NOINTERNET} />
                )
              }
            />
            <Route
              exact
              path={ROUTES.NOINTERNET}
              element={<NoInternetPage />}
            />
            <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
          </Routes>
          {hasFooter.includes(location.pathname) && <Footer />}
        </div>
      )}
    </>
  );
}

export default NoInternet;

import { useEffect, useLayoutEffect } from "react";
import { getCurrentLanguageToService, getToken } from "services";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import i18next from "localization/i18next";
import NoInternet from "modules/NoInternet/NoInternet";
// componentss
import Routes from "modules/Routes";
import Footer from "modules/components/Footer";
import NewNavbar from "modules/components/NewNavbar";
// Styling
import "render-smooth-image-react/build/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./firebase";
import useSyncDeleteDownloads from "modules/MyDownloads/useSyncDeleteDownloads";
import { useSearchParams } from "react-router-dom";
import { changeLang } from "store/lang";
import i18n from "localization/i18next";

const queryClient = new QueryClient();

function App({ getCurrentDir }) {
  const { lang, dir } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const language = !searchParams.get("lang") ? null : searchParams.get("lang");

  function changeLangAction() {
    i18n.changeLanguage(language);
    dispatch(changeLang(language));
    getCurrentLanguageToService(language);
  }
  useLayoutEffect(() => {
    if (language) {
      changeLangAction();
    }
  }, [language]);
  const isLiveOrPreprod = process.env.REACT_APP_ENV === "live";
  //Disable inspect menu
  useEffect(() => {
    if (isLiveOrPreprod) {
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    }
    return () => {
      document.removeEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    };
  });

  getToken(authorization?.token);
  getCurrentLanguageToService(lang);
  useEffect(() => {
    i18next.init({
      lng: lang,
      fallbackLng: lang,
    });
    getCurrentDir(dir);
    //eslint-disable-next-line
  }, [lang, dir]);

  return (
    <QueryClientProvider client={queryClient}>
      <NoInternet>
        <>
          <Helmet>
            <html lang={lang} dir={dir}></html>
          </Helmet>
          <ToastContainer dir={dir} />
          <div className="app-container">
            <NewNavbar />
            <Routes />
            <Footer />
          </div>
        </>
      </NoInternet>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;

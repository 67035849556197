import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import closeIcon from "../../../assets/icons/close.svg";
// import downloading from "../../../assets/icons/downloadingIcon.json";
import { t } from "i18next";
import { Radio } from "antd";
import { addDataToIndexDB, dbInfo } from "../../../helpers/indexdb";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { addVideoToDownloads } from "../network";
// import Lottie from "react-lottie";
import { toastSuccess } from "helpers/toasters";
import { baseUrl } from "services";
import DownloadProgress from "./DownloadProgress";

function DownloadModal({ isModalVisible, onCancel, videoDetails }) {
  const [percentage, setPercentage] = useState(0);
  const [fileInfo, setfileInfo] = useState("");
  const [audioList, updateAudioList] = useState([]);
  const [subtitleList, updateSubtitleList] = useState([]);
  const [qualityList, setQualityList] = useState([]);
  const [selectedAudio, updateSelectedAudio] = useState(null);
  const [selectedSubtitle, updateSelectedSubtitle] = useState(null);
  const [selectedQuality, setSelectedQuality] = useState(null);
  const [isDownloading, updateIsDownloading] = useState(false);
  const { authorization } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const updateProgressFiles = (percentage, fileInfo) => {
    setPercentage(percentage);
    setfileInfo(fileInfo);
  };

  const onQualityChange = (e) => {
    setSelectedQuality(e.target.value);
  };

  const onAudioChange = (e) => {
    updateSelectedAudio(e.target.value);
  };

  const onSubtitleChange = (e) => {
    updateSelectedSubtitle(e.target.value);
  };

  useEffect(() => {
    let SubtitlesArr = [];
    SubtitlesArr.push({ languageName: t("offf"), _id: null });
    if (videoDetails?.subtitles) {
      SubtitlesArr = [...SubtitlesArr, ...videoDetails?.subtitles];
    }
    updateAudioList(videoDetails.audios);
    updateSubtitleList(SubtitlesArr);

    updateSelectedSubtitle(SubtitlesArr[0]?._id);
    if (videoDetails?.audios?.length) {
      updateSelectedAudio(videoDetails?.audios[0]?._id);
    }

    setSelectedQuality(videoDetails.videoPath);
    setQualityList((prev) =>
      [
        videoDetails?.videoPath
          ? { quality: "HD", path: videoDetails.videoPath }
          : null,
        videoDetails?.lowQualityVideoPath
          ? { quality: "Low", path: videoDetails.lowQualityVideoPath }
          : null,
      ].filter(Boolean),
    );
  }, [videoDetails]);

  const urlReplacement = (oldURL) => encodeURI(oldURL)?.replace(/%5C/g, "/");
  const addTokenToMedia = (path, type) => {
    var token = authorization?.token;
    return baseUrl + path + "?token=" + token;
  };
  const download = () => {
    updateIsDownloading(true);
    let videoData = {
      videoId: videoDetails.videoId,
      videoHeaderId: videoDetails.videoHeaderId,
      videoPath: [
        ["imgPath", urlReplacement(addTokenToMedia(videoDetails.imgPath))],
        [
          "audioPath",
          urlReplacement(
            addTokenToMedia(
              videoDetails.audios?.filter(
                (audio) => audio._id === selectedAudio,
              )[0].path,
            ),
          ),
        ],
        ["videoPath", urlReplacement(addTokenToMedia(selectedQuality))],
      ],
      title: videoDetails.title,
      genre: videoDetails.genre,
      category: videoDetails.category,
      isSeries: params.qp.split("-")[2],
      audios: videoDetails.audios?.filter(
        (audio) => audio._id === selectedAudio,
      ),
    };
    let publicVideoData = {
      userAccountId: authorization.online._id,
      videoId: videoDetails.videoId,
      audio: videoDetails.audios?.filter(
        (audio) => audio._id === selectedAudio,
      )[0].path,
      audios: videoDetails.audios?.filter(
        (audio) => audio._id === selectedAudio,
      ),
      isDeleted: false,
      genre: videoDetails.genre,
      category: videoDetails.category,
      audioLanguage: videoDetails.audios?.filter(
        (audio) => audio._id === selectedAudio,
      )[0].languageName,
    };
    if (selectedSubtitle) {
      videoData.videoPath.push([
        "subtitlePath",
        addTokenToMedia(
          videoDetails.subtitles?.filter(
            (subtitle) => subtitle._id === selectedSubtitle,
          )[0].vttPath,
        ),
      ]);
      publicVideoData.subtitle = videoDetails.subtitles?.filter(
        (subtitle) => subtitle._id === selectedSubtitle,
      )[0].vttPath;
      videoData.subtitles = videoDetails.subtitles?.filter(
        (subtitle) => subtitle._id === selectedSubtitle,
      );
      publicVideoData.subtitleLanguage = videoDetails.subtitles?.filter(
        (subtitle) => subtitle._id === selectedSubtitle,
      )[0].languageName;
      publicVideoData.subtitles = videoDetails.subtitles?.filter(
        (subtitle) => subtitle._id === selectedSubtitle,
      );
    }

    addDataToIndexDB(
      authorization.online._id,
      dbInfo.dbVersion,
      videoData,
      () => {
        toastSuccess(t("downloadingSuccess"));
        addVideoToDownloads(
          publicVideoData,
          (success) => {},
          (fail) => {},
        );
        onCancel();
        updateIsDownloading(false);
      },
      authorization.online._id,
      updateProgressFiles,
    );
  };

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: downloading,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };
  return (
    <Modal
      centered
      open={isModalVisible}
      width={800}
      onCancel={onCancel}
      className='download-modal modal-container'
      footer={false}
      closeIcon={<img src={closeIcon} alt='icon' style={{ width: "30px" }} />}
      destroyOnClose
    >
      {isDownloading ? (
        <div className='text-center'>
          <h5 className='text-center fw-700 f-25 title my-4'>
            {t("downloading")}
          </h5>

          <div className='my-4 py-4'>
            {/* <Lottie
              isClickToPauseDisabled={true}
              options={defaultOptions}
              height={120}
              width={120}
            /> */}
            <DownloadProgress percentage={percentage} fileInfo={fileInfo} />
          </div>
        </div>
      ) : (
        <div className='px-4 mx-4'>
          <h5 className='text-center fw-700 f-25 title my-4'>
            {t("chooseToDownload")}
          </h5>
          <div className='quality-section mb-4'>
            <div className='fw-600 f-22'>{t("quality")}</div>
            <Radio.Group onChange={onQualityChange} value={selectedQuality}>
              {qualityList?.map((quality) => {
                return (
                  <Radio
                    key={quality?.path}
                    value={quality?.path}
                    className='fw-600 f-18 me-4'
                  >
                    {quality.quality}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
          <div className='audios-section mb-4'>
            <div className='subtitle fw-600 f-22'>{t("audio")}</div>
            <Radio.Group onChange={onAudioChange} value={selectedAudio}>
              {audioList?.map((audio) => {
                return (
                  <Radio
                    key={audio._id}
                    value={audio._id}
                    className='fw-600 f-18 me-4'
                  >
                    {audio.languageName}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
          <div className='subtitles-section my-4'>
            <div className='subtitle fw-600 f-22'>{t("subtitle")}</div>
            <Radio.Group onChange={onSubtitleChange} value={selectedSubtitle}>
              {subtitleList?.map((subtitle) => {
                return (
                  <Radio
                    key={subtitle._id}
                    value={subtitle._id}
                    className='fw-600 f-18 me-4'
                  >
                    {subtitle.languageName}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
          <div className='text-center my-4'>
            <Button className='button-primary' onClick={download}>
              {t("ok")}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default DownloadModal;

export const detectInternetSpeed = async () => {
	const fileSize = 1024 * 1024; // 1 MB
	const fileUrl = "https://source.unsplash.com/random/1000x1000";
	const startTime = performance.now();
	var speed = null;
	await fetch(fileUrl, {
		headers: { Range: `bytes=0-${fileSize - 1}` },
	}).then((response) => {
		const endTime = performance.now();
		const duration = (endTime - startTime) / 1000; // convert to seconds
		const speedMbps = (fileSize / duration / 1024 / 1024).toFixed(2);
		speed = speedMbps;
	});
	return speed;
};

import axios from "axios";
import { responseErrorHandeler } from "helpers/responseErrorHandeler";
export const baseUrl = process.env.REACT_APP_BASE_URL;
//export const baseUrl = "https://api.amusethekidz.com/";
let token = null;
var currentLang = "en";
export function getToken(newToken) {
  token = newToken;
}
export function getCurrentLanguageToService(lang) {
  currentLang = lang;
}

export function getResource(path, onSuccess, onFail, reqAuth = true) {
  let requestData = {
    method: "get",
    url: baseUrl + path,
    headers: {
    },
  };

  if (reqAuth) {
    requestData.headers = {
      Authorization: "Bearer " + token,
      "Accept-Language": currentLang,
    };
  }

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      onFail(fail.response);
      responseErrorHandeler(fail.response?.status);
    });
}

export function postResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multiPart = false,
  headers,
) {
  let requestData = {
    method: "post",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (headers) {
    requestData.headers = { ...headers, "Accept-Language": currentLang, };
  }

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
      // "Accept-Language": currentLang,
    };
  }

  if (multiPart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  if (navigator.onLine) {
    axios(requestData)
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((fail) => {
        onFail(fail.response);
        responseErrorHandeler(fail?.response?.status);
      });
  }
}

export function deleteResource(path, onSuccess, onFail, data, reqAuth = true) {
  let requestData = {
    method: "delete",
    url: baseUrl + path,
    headers: {},
  };
  if (data) requestData.data = data;

  if (token && reqAuth) {
    requestData.headers = {
      Authorization: "Bearer " + token,
      "Accept-Language": currentLang,
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      onFail(fail.response);
      responseErrorHandeler(fail.response.status);
    },
  );
}

export function patchResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false,
) {
  let requestData = {
    method: "patch",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
      "Accept-Language": currentLang,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      onFail(fail.response);
      responseErrorHandeler(fail.response.status);
    },
  );
}
export function putResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false,
) {
  let requestData = {
    method: "put",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
      "Accept-Language": currentLang,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      onFail(fail.response);
      responseErrorHandeler(fail.response.status);
    },
  );
}
